var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as ChakraButton } from "@chakra-ui/react";
import { forwardRef } from "react";
import { METRIPORT_PRIMARY_FADED } from "../shared-logic/style";
export const METRIPORT_PRIMARY = "#748df0";
export const METRIPORT_PRIMARY_HOVER_BG = "#879ced";
export const defaultButtonSettings = {
    bg: METRIPORT_PRIMARY,
    color: "white",
    _hover: {
        bg: METRIPORT_PRIMARY_HOVER_BG,
    },
};
export const Button = forwardRef((_a, ref) => {
    var { type, onClick, isLoading, loadingText, disabled, children } = _a, rest = __rest(_a, ["type", "onClick", "isLoading", "loadingText", "disabled", "children"]);
    return (_jsx(ChakraButton, Object.assign({ ref: ref }, (rest.colorScheme
        ? undefined
        : {
            bg: rest.variant === "outline" ? "transparent" : METRIPORT_PRIMARY,
            color: rest.variant === "outline" ? METRIPORT_PRIMARY : "white",
            borderColor: rest.variant === "outline" ? METRIPORT_PRIMARY : "transparent",
            _hover: {
                bg: rest.variant === "outline" ? "transparent" : METRIPORT_PRIMARY_FADED,
                color: rest.variant === "outline" ? METRIPORT_PRIMARY_FADED : "white",
                borderColor: rest.variant === "outline" ? METRIPORT_PRIMARY_FADED : "transparent",
            },
        }), { onClick: onClick, isLoading: isLoading !== null && isLoading !== void 0 ? isLoading : false, loadingText: loadingText, isDisabled: disabled !== null && disabled !== void 0 ? disabled : false, type: type }, rest, { children: children })));
});
