import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle, getValidCode } from "../shared";
export const vitalTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "observation" },
        { field: "mostRecentValue" },
        { field: "mostRecentDate", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    const observations = getResourcesFromBundle(bundle, "Observation");
    const vitals = getVital(observations);
    const groupedVitals = groupVitals(vitals);
    return {
        columnDefs,
        rowData: getVitalRowData({ vitals: groupedVitals, tableFilters }),
    };
};
export function getVital(observations) {
    var _a;
    const vital = [];
    for (const observation of observations) {
        const isVital = (_a = observation.category) === null || _a === void 0 ? void 0 : _a.find(ext => { var _a, _b, _c; return ((_c = (_b = (_a = ext.coding) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "vital-signs"; });
        if (isVital) {
            vital.push(observation);
        }
    }
    return vital;
}
export function groupVitals(vitals) {
    const results = [];
    const observationMap = new Map();
    vitals.map(v => {
        var _a, _b, _c, _d, _e, _f;
        let title;
        const codings = getValidCode((_a = v.code) === null || _a === void 0 ? void 0 : _a.coding);
        const displays = codings.map(coding => coding.display);
        if (displays.length) {
            title = Array.from(new Set(displays)).join(", ");
        }
        else if ((_b = v.code) === null || _b === void 0 ? void 0 : _b.text) {
            title = v.code.text;
        }
        else {
            results.push({ title: "-", mostRecentObservation: v });
            return;
        }
        const observationValue = (_d = (_c = v.valueQuantity) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : v.valueString;
        if (!v.effectiveDateTime || !observationValue) {
            results.push({ title, mostRecentObservation: v });
            return;
        }
        const observationPoint = {
            rawVital: v,
            date: v.effectiveDateTime,
            observation: typeof observationValue === "number" ? observationValue : parseInt(observationValue),
            // TODO: Make sure all data points have the same unit
            unit: (_f = (_e = v.valueQuantity) === null || _e === void 0 ? void 0 : _e.unit) === null || _f === void 0 ? void 0 : _f.replace(/[{()}]/g, ""),
        };
        title = handleTitleSpecialCases(title, observationPoint);
        const groupedObservation = observationMap.get(title);
        if (groupedObservation) {
            groupedObservation.push(observationPoint);
            observationMap.set(title, groupedObservation);
        }
        else {
            observationMap.set(title, [observationPoint]);
        }
    });
    handleBloodPressureMapping(observationMap);
    Array.from(observationMap.entries()).map(([title, values]) => {
        const sortedPoints = values.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        const mostRecent = sortedPoints[sortedPoints.length - 1];
        if (!mostRecent)
            return;
        results.push({
            title,
            mostRecentObservation: mostRecent.rawVital,
            sortedPoints: sortedPoints.map(p => {
                var _a;
                return ({
                    value: p.observation,
                    date: p.date,
                    unit: p.unit,
                    bp: (_a = p.bp) !== null && _a !== void 0 ? _a : undefined,
                });
            }),
        });
    });
    return results;
}
function handleBloodPressureMapping(obsMap) {
    const bloodPressure = obsMap.get("Blood Pressure");
    if (!bloodPressure)
        return;
    const groupedBloodPressure = [];
    const systolicMap = new Map();
    const diastolicMap = new Map();
    bloodPressure.forEach(bp => {
        var _a, _b;
        if ((_a = bp.grouping) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("systolic")) {
            systolicMap.set(bp.date, bp.observation);
        }
        else if ((_b = bp.grouping) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes("diastolic")) {
            diastolicMap.set(bp.date, bp.observation);
        }
    });
    bloodPressure.forEach(bp => {
        var _a, _b;
        if ((_a = bp.grouping) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("systolic")) {
            const diastolicValue = diastolicMap.get(bp.date);
            if (diastolicValue !== undefined) {
                groupedBloodPressure.push(Object.assign(Object.assign({}, bp), { bp: {
                        systolic: bp.observation,
                        diastolic: diastolicValue,
                    } }));
            }
        }
        else if ((_b = bp.grouping) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes("diastolic")) {
            const systolicValue = systolicMap.get(bp.date);
            if (systolicValue !== undefined) {
                if (!groupedBloodPressure.some(gbp => { var _a; return gbp.date === bp.date && ((_a = gbp.bp) === null || _a === void 0 ? void 0 : _a.diastolic) === bp.observation; })) {
                    groupedBloodPressure.push(Object.assign(Object.assign({}, bp), { bp: {
                            systolic: systolicValue,
                            diastolic: bp.observation,
                        } }));
                }
            }
        }
    });
    obsMap.set("Blood Pressure", groupedBloodPressure);
}
function getVitalRowData({ vitals, tableFilters, }) {
    return vitals === null || vitals === void 0 ? void 0 : vitals.map(vitals => {
        var _a, _b, _c, _d;
        return ({
            id: (_a = vitals.mostRecentObservation.id) !== null && _a !== void 0 ? _a : "-",
            observation: (_b = vitals.title) !== null && _b !== void 0 ? _b : getVitalsDisplay(vitals.mostRecentObservation),
            mostRecentValue: (_d = renderBpValue((_c = vitals.sortedPoints) === null || _c === void 0 ? void 0 : _c[0])) !== null && _d !== void 0 ? _d : renderVitalsValue(vitals.mostRecentObservation),
            mostRecentDate: getVitalsDate(vitals.mostRecentObservation),
        });
    }).filter(row => filterByDate(row.mostRecentDate, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getVitalsDisplay(vitals) {
    var _a, _b;
    const codings = getValidCode((_a = vitals.code) === null || _a === void 0 ? void 0 : _a.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
        return displays.join(", ");
    }
    else if ((_b = vitals.code) === null || _b === void 0 ? void 0 : _b.text) {
        return vitals.code.text;
    }
    return "-";
}
function renderVitalsValue(vitals) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (vitals.valueQuantity) {
        const value = (_a = vitals.valueQuantity) === null || _a === void 0 ? void 0 : _a.value;
        const unit = (_c = (_b = vitals.valueQuantity) === null || _b === void 0 ? void 0 : _b.unit) === null || _c === void 0 ? void 0 : _c.replace(/[{()}]/g, "");
        return unit ? `${value} ${unit}` : `${value}`;
    }
    else if (vitals.valueCodeableConcept) {
        return ((_g = (_e = (_d = vitals.valueCodeableConcept) === null || _d === void 0 ? void 0 : _d.text) !== null && _e !== void 0 ? _e : (_f = getValidCode(vitals.valueCodeableConcept.coding)[0]) === null || _f === void 0 ? void 0 : _f.display) !== null && _g !== void 0 ? _g : "-");
    }
    else {
        return "-";
    }
}
function getVitalsDate(vitals) {
    return dayjs(vitals.effectiveDateTime).format(ISO_DATE);
}
export function renderBpValue(dataPoint) {
    const bp = dataPoint === null || dataPoint === void 0 ? void 0 : dataPoint.bp;
    if (!bp)
        return undefined;
    return `${bp.systolic}/${bp.diastolic} ${dataPoint.unit}`;
}
function handleTitleSpecialCases(title, observationPoint) {
    let updatedTitle = title;
    if (title.toLowerCase().includes("blood pressure") ||
        title.toLowerCase().includes("bp sys") ||
        title.toLowerCase().includes("bp dias")) {
        observationPoint.grouping = title;
        updatedTitle = "Blood Pressure";
    }
    if (title.toLowerCase().includes("bmi")) {
        updatedTitle = "Body Mass Index (BMI)";
    }
    return updatedTitle;
}
