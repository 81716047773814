import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, Flex } from "@chakra-ui/react";
import { Button } from "./button";
import { useDownloadFile } from "../shared-logic/useDownloadFile";
export function SidePanelContent({ title, sourceDocument, children, }) {
    var _a, _b;
    const { isDownloading, onDownloadFile } = useDownloadFile();
    return (_jsxs(Box, { children: [_jsxs(Flex, Object.assign({ justifyContent: "space-between" }, { children: [_jsx(Heading, Object.assign({ mb: "3" }, { children: title })), _jsx(Button, Object.assign({ isLoading: (_b = (_a = isDownloading[sourceDocument.id]) === null || _a === void 0 ? void 0 : _a.downloading) !== null && _b !== void 0 ? _b : false, onClick: () => {
                            onDownloadFile(sourceDocument.id, sourceDocument.fileName, "html");
                        } }, { children: "View Source" }))] })), children] }));
}
