var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from "react";
import { usStateSchema, } from "@metriport/api-sdk";
import { cloneDeep } from "lodash";
import { useMetriportApi } from "./useMetriportApi";
import { DRIVERS_LICENSE } from "../shared-components/patient-form";
import { filterTruthy } from "./filter-map-utils";
import { useAnalyticsContext, Actions, Features } from "./analytics-context";
import useMetriportToast from "./useMetriportToast";
export function useSubmitPatient({ patientId, onComplete, }) {
    const metriportApi = useMetriportApi();
    const Analytics = useAnalyticsContext();
    const { success, warning } = useMetriportToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const createOrUpdatePatient = (patientForm) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const patient = formToApi(patientForm);
        setIsSubmitting(true);
        try {
            let resp;
            if (patientId) {
                Analytics.emit(Actions.update, Features.patient);
                resp = yield metriportApi.updatePatient(Object.assign(Object.assign({}, patient), { id: patientId }), patientForm.facilityId);
                success({ title: "Patient saved." });
            }
            else {
                Analytics.emit(Actions.create, Features.patient);
                resp = yield metriportApi.createPatient(patient, patientForm.facilityId);
            }
            return resp;
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (err) {
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 423) {
                warning({
                    title: "Patient temporarily locked",
                    description: "The patient can't be updated while Document Query is in progress. Please try again later or reach out to support@metriport.com if this is not expected.",
                    duration: 5000,
                });
            }
            return undefined;
        }
        finally {
            setIsSubmitting(false);
            onComplete();
        }
    });
    return { createOrUpdatePatient, isSubmitting };
}
const formToApi = (form) => {
    const formCopy = cloneDeep(form);
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete formCopy.driversLicense; // safe on this context, don't use formCopy for anything else
    const personalIdentifiers = [
        form.driversLicense.value && form.driversLicense.state
            ? {
                type: DRIVERS_LICENSE,
                value: form.driversLicense.value,
                state: usStateSchema.parse(form.driversLicense.state),
            }
            : undefined,
    ]
        .flatMap(filterTruthy)
        .filter(pid => pid.type.trim().length > 0 && pid.value.trim().length > 0);
    return Object.assign(Object.assign({}, formCopy), { contact: form.contact.filter(c => c.phone !== undefined || c.email !== undefined), address: [
            Object.assign(Object.assign({}, form.address[0]), { state: usStateSchema.parse(form.address[0].state) }),
            ...form.address.slice(1).map(address => {
                return Object.assign(Object.assign({}, address), { state: usStateSchema.parse(address.state) });
            }),
        ], personalIdentifiers });
};
