import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, Flex, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Button } from "./button";
import { DateRange } from "../../shared/form/DateRange";
import { Input } from "../../shared/form/Input";
const docRefDateRangeId = "doc-ref-date-range";
const docRefContentId = "doc-ref-content";
const MIN_CONTENT_LENGTH = 3;
export function SearchModal({ onSearchDocuments, isOpen, onClose, isFetchingDocuments, searchDocFilters, }) {
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [content, setContent] = useState("");
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);
    useEffect(() => {
        if (searchDocFilters) {
            const { dateFrom, dateTo, content } = searchDocFilters;
            setDateFrom(dateFrom !== null && dateFrom !== void 0 ? dateFrom : "");
            setDateTo(dateTo !== null && dateTo !== void 0 ? dateTo : "");
            setContent(content !== null && content !== void 0 ? content : "");
        }
    }, []);
    useEffect(() => {
        const isDisabled = Boolean(content && content.length > 0 && content.length < MIN_CONTENT_LENGTH);
        setIsSearchDisabled(isDisabled);
    }, [content]);
    const clearFilters = () => {
        setDateFrom("");
        setDateTo("");
        setContent("");
        onSearchDocuments({});
        onClose();
    };
    const doSearch = () => !isSearchDisabled && onSearchDocuments({ dateFrom, dateTo, content });
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "2xl" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ px: 6, py: 4 }, { children: [_jsx(Text, Object.assign({ mb: 1, fontSize: "xl", fontWeight: "bold" }, { children: "Search Documents" })), _jsx(ModalCloseButton, {}), _jsx(Text, Object.assign({ color: "gray.400", mb: 0 }, { children: "This is to search through existing documents that have been queried across the networks." })), _jsxs(HStack, Object.assign({ mb: 3, alignItems: "end" }, { children: [_jsx(DateRange, { id: docRefDateRangeId, isRequired: false, label: "Filter by date", dateFrom: dateFrom, dateTo: dateTo, onSetToDate: (to) => setDateTo(to), onSetFromDate: (from) => setDateFrom(from) }), _jsx(Input, { id: docRefContentId, value: content, isRequired: false, label: `Content (min ${MIN_CONTENT_LENGTH} char)`, onChange: (e) => setContent(e.target.value), onEnterKeyPress: doSearch })] })), _jsxs(Flex, Object.assign({ flexDirection: "row", justifyContent: "flex-end" }, { children: [_jsx(Button, Object.assign({ isLoading: isFetchingDocuments, disabled: isSearchDisabled, onClick: () => {
                                    doSearch();
                                    onClose();
                                } }, { children: "Search" })), _jsx(Button, Object.assign({ ml: 3, style: { backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }, variant: "outline", onClick: clearFilters }, { children: "Clear" }))] }))] }))] })));
}
