import { jsx as _jsx } from "react/jsx-runtime";
import { Box, VStack } from "@chakra-ui/react";
import Chart from "react-apexcharts";
export function VitalsChart({ vitalsId, vitals, vitalsSecondary, dates, }) {
    const yAxisMin = vitalsSecondary
        ? Math.min(...vitals.values, ...vitalsSecondary.values) -
            Math.floor(Math.min(...vitals.values, ...vitalsSecondary.values) / 2)
        : Math.min(...vitals.values) - Math.floor(Math.min(...vitals.values) / 2);
    const dataSeries = [
        {
            name: vitals.title,
            data: vitals.values,
        },
    ];
    if (vitalsSecondary) {
        dataSeries.push({
            name: vitalsSecondary.title,
            data: vitalsSecondary.values,
        });
    }
    return (_jsx(Box, Object.assign({ w: "100%" }, { children: _jsx(VStack, Object.assign({ align: "start" }, { children: _jsx(Chart, { options: {
                    chart: {
                        id: `${vitalsId}-chart`,
                    },
                    xaxis: {
                        categories: dates,
                    },
                    yaxis: {
                        min: yAxisMin,
                        decimalsInFloat: 1,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                }, series: dataSeries, type: "line", width: 500, height: 320, toolbar: {
                    show: true,
                } }) })) })));
}
