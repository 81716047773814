var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SidePanelContent } from "../../side-panel-content";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
export function FamilyMemberHistoryContent(_a) {
    var _b, _c, _d, _e, _f;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const selectedFamilyMemberHistory = (_b = mappedConsolidated === null || mappedConsolidated === void 0 ? void 0 : mappedConsolidated["FamilyMemberHistory"]) === null || _b === void 0 ? void 0 : _b[rowContents.id];
    const valueString = (_e = (_d = (_c = selectedFamilyMemberHistory === null || selectedFamilyMemberHistory === void 0 ? void 0 : selectedFamilyMemberHistory.extension) === null || _c === void 0 ? void 0 : _c.find(ext => ext.valueString)) === null || _d === void 0 ? void 0 : _d.valueString) !== null && _e !== void 0 ? _e : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Family Member History", sourceDocument: {
            id: (_f = selectedFamilyMemberHistory === null || selectedFamilyMemberHistory === void 0 ? void 0 : selectedFamilyMemberHistory.id) !== null && _f !== void 0 ? _f : "",
            fileName: valueString,
        } }, { children: _jsx(FamilyMemberHistoryDisplay, { familyMemberHistory: selectedFamilyMemberHistory, tableRow: rowContents }) })));
}
function FamilyMemberHistoryDisplay({ familyMemberHistory, tableRow, }) {
    if (!familyMemberHistory) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: familyMemberHistory.id,
                    familyMember: tableRow.familyMember,
                    sex: tableRow.sex,
                    conditions: tableRow.conditions,
                    deceased: tableRow.deceased,
                } }), _jsx(CodesDisplay, { code: familyMemberHistory === null || familyMemberHistory === void 0 ? void 0 : familyMemberHistory.reasonCode })] }));
}
