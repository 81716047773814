import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Text } from "@chakra-ui/react";
import { toArray } from "@metriport/shared";
import { getSystemDisplay } from "../shared";
import { SidePanelSection } from "./sidepanel-section";
export function CodesDisplay({ code }) {
    if (!code) {
        return null;
    }
    const codeableConcepts = toArray(code);
    const hasTexts = codeableConcepts.some(c => c.text);
    const hasValidCodes = codeableConcepts.some(c => { var _a; return (_a = c.coding) === null || _a === void 0 ? void 0 : _a.some(c => c.system && (c.code || c.display)); });
    if (!hasValidCodes && !hasTexts) {
        return null;
    }
    return (_jsx(SidePanelSection, Object.assign({ title: "Codes" }, { children: codeableConcepts.map((c, i) => {
            var _a, _b;
            const validCodes = (_b = (_a = c.coding) === null || _a === void 0 ? void 0 : _a.filter(c => c.system && (c.code || c.display))) !== null && _b !== void 0 ? _b : [];
            return (_jsxs(Box, Object.assign({ mb: i < codeableConcepts.length - 1 ? 4 : 0 }, { children: [c.text && (_jsxs(Box, Object.assign({ mb: 2, flexDirection: "row" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Text:" })), _jsx(Text, { children: c.text })] }))), validCodes.length > 0 && (_jsxs(Box, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Codes:" })), validCodes.map((c, i) => (_jsxs(_Fragment, { children: [_jsxs(Box, { children: [c.system && _jsxs(Text, { children: ["System: ", getSystemDisplay(c.system)] }), c.code && _jsxs(Text, { children: ["Code: ", c.code] }), c.display && _jsxs(Text, { children: ["Display: ", c.display] })] }, i), i < validCodes.length - 1 && _jsx(Divider, { my: 2 })] })))] }))] }), i));
        }) })));
}
