var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import useMetriportToast from "./useMetriportToast";
import { useMetriportApi } from "./useMetriportApi";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { getConsolidatedWebhook } from "../../../api/consolidated-bundle";
import { capture } from "../../../shared/capture";
const POLLING_INTERVAL = dayjs.duration(1, "second").asMilliseconds();
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();
export function useGetConsolidated({ patientId, canQueryConsolidated, }) {
    const toast = useMetriportToast();
    const metriportApi = useMetriportApi();
    const [consolidatedRequestId, setConsolidatedRequestId] = useState(undefined);
    const [isConsolidatedLoading, setIsConsolidatedLoading] = useState(false);
    const [consolidated, setConsolidated] = useState(undefined);
    useEffect(() => {
        if (canQueryConsolidated) {
            setIsConsolidatedLoading(true);
            getPatientConsolidated();
        }
    }, [canQueryConsolidated]);
    function getPatientConsolidated() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const query = yield metriportApi.startConsolidatedQuery(patientId, undefined, undefined, undefined, "json", true);
                setConsolidatedRequestId(query.requestId);
                toast.info({
                    title: "Loading the patient's consolidated data. This may take some time depending on how much data is available.",
                    duration: EXPLAIN_TOAST_DURATION,
                });
            }
            catch (err) {
                const msg = "Failed to load patient consolidated data";
                capture.error(msg, {
                    extra: { patient: patientId, context: `patient.get.consolidated`, err },
                });
                toast.error({ title: msg });
            }
        });
    }
    useInterval(() => __awaiter(this, void 0, void 0, function* () {
        const { queries } = yield metriportApi.getConsolidatedQueryStatus(patientId);
        const allDone = queries === null || queries === void 0 ? void 0 : queries.every(query => query.status === "completed");
        if (allDone && consolidatedRequestId) {
            const requestId = consolidatedRequestId;
            const webhook = yield getConsolidatedWebhook(patientId, requestId);
            const { fileUrl } = webhook;
            if (fileUrl) {
                const bundle = yield fetch(fileUrl).then(res => res.json());
                setConsolidated(bundle);
                setIsConsolidatedLoading(false);
                setConsolidatedRequestId(undefined);
            }
            else {
                setConsolidatedRequestId(undefined);
                setIsConsolidatedLoading(false);
                const msg = "Failed to load patient consolidated data";
                capture.error(msg, {
                    extra: { patient: patientId, context: `patient.get.consolidated`, webhook },
                });
            }
        }
    }), isConsolidatedLoading ? POLLING_INTERVAL : null);
    return { consolidated, isConsolidatedLoading };
}
