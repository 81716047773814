var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { z } from "zod";
import { nonStrictAddressSchema } from "./medical/address";
import { apiOSS } from "./apiOSS";
const PATIENT_URL = `/medical/v1/patient`;
const patientFacilityMatchSchema = z.object({
    name: z.string(),
    oid: z.string(),
    address: nonStrictAddressSchema,
    patient: z
        .object({
        dob: z.string().nullable(),
        gender: z.string().nullable(),
        names: z.array(z.string()),
        addresses: z.array(z.string()),
        telephoneNumbers: z.array(z.string()),
        emails: z.array(z.string()),
        driversLicenses: z.array(z.string()),
        ssns: z.array(z.string()),
    })
        .optional(),
});
const patientFacilityMatchesSchema = z.array(patientFacilityMatchSchema);
export function getPatientFacilityMatches(patientId) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield apiOSS.get(`${PATIENT_URL}/${patientId}/facility-matches`);
        return patientFacilityMatchesSchema.parse(resp.data);
    });
}
