import * as Sentry from "@sentry/react";
import { envTypeSandbox, getEnvType, isProdEnv } from "./util";
export const capture = {
    environment: getEnvType(),
    setUser: (user) => {
        Sentry.setUser(user);
    },
    /**
     * Captures an exception event and sends it to Sentry.
     *
     * @param error — An Error object.
     * @param captureContext — Additional scope data to apply to exception event.
     * @returns — The generated eventId.
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (error, captureContext) => {
        var _a;
        if (typeof error === "string") {
            return capture.message(error, Object.assign(Object.assign({}, captureContext), { level: (_a = captureContext === null || captureContext === void 0 ? void 0 : captureContext.level) !== null && _a !== void 0 ? _a : "error" }));
        }
        const extra = captureContext ? stringifyExtra(captureContext) : {};
        return Sentry.captureException(error, Object.assign(Object.assign({}, captureContext), { extra }));
    },
    /**
     * Captures an exception event and sends it to Sentry.
     *
     * @param message The message to send to Sentry.
     * @param captureContext — Additional scope data to apply to exception event.
     * @returns — The generated eventId.
     */
    message: (message, captureContext) => {
        const extra = captureContext ? stringifyExtra(captureContext) : {};
        return Sentry.captureMessage(message, Object.assign(Object.assign({}, captureContext), { extra }));
    },
    getEnvironment: () => {
        return capture.environment;
    },
    /**
     * Updates the Sentry environment to Sandbox if we are in production and the user is in sandbox mode.
     *
     * @param isSandbox whether the user is in sandbox mode.
     */
    updateSandboxEnvironment(isSandbox) {
        capture.environment = isProdEnv() && isSandbox ? envTypeSandbox : getEnvType();
        Sentry.configureScope(function (scope) {
            scope.addEventProcessor(function (event) {
                event.environment = capture.getEnvironment();
                return event;
            });
        });
    },
};
function stringifyExtra(captureContext) {
    var _a;
    return Object.entries((_a = captureContext.extra) !== null && _a !== void 0 ? _a : {}).reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: typeof value === "string" ? value : JSON.stringify(value, null, 2) })), {});
}
