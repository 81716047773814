import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { SidePanelSection } from "./sidepanel-section";
export function PeriodDisplay({ period, fallbackDate, title, }) {
    return (_jsx(SidePanelSection, Object.assign({ title: title !== null && title !== void 0 ? title : "Period" }, { children: _jsx(DateDisplay, { period: period, fallbackDate: fallbackDate }) })));
}
function DateDisplay({ period, fallbackDate, }) {
    if (!period && !fallbackDate) {
        return (_jsx(Text, { children: fallbackDate ? `Date: ${dayjs(fallbackDate).format(ISO_DATE)}` : "No Period" }));
    }
    else if (!period) {
        return _jsx(Text, { children: `Date: ${dayjs(fallbackDate).format(ISO_DATE)}` });
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(Text, { children: ["Start: ", dayjs(period.start).format(ISO_DATE)] }), _jsxs(Text, { children: ["End: ", dayjs(period.end).format(ISO_DATE)] })] }));
    }
}
