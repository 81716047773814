import { useMemo } from "react";
import { MetriportMedicalApi } from "@metriport/api-sdk";
import axios from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSharedContext } from "./shared-context";
dayjs.extend(duration);
const mapiTimeout = dayjs.duration({ seconds: 29 });
const headers = {
    [MetriportMedicalApi.headers.clientApp]: "Metriport-Dashboard",
};
export function useMetriportApi() {
    const { sharedState } = useSharedContext();
    const metriportAPI = useMemo(() => {
        var _a;
        const options = Object.assign({ axios, timeout: mapiTimeout.asMilliseconds(), mode: "jwt", baseAddress: sharedState.baseUrl }, headers);
        return new MetriportMedicalApi((_a = sharedState.authToken) !== null && _a !== void 0 ? _a : "", options);
    }, [sharedState.authToken, sharedState.baseUrl]);
    return metriportAPI;
}
