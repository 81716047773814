var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SidePanelContent } from "../../side-panel-content";
import { PeriodDisplay } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
export function LabContent(_a) {
    var _b, _c, _d, _e, _f;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const selectedLab = (_b = mappedConsolidated === null || mappedConsolidated === void 0 ? void 0 : mappedConsolidated["Observation"]) === null || _b === void 0 ? void 0 : _b[rowContents.id];
    const valueString = (_e = (_d = (_c = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.extension) === null || _c === void 0 ? void 0 : _c.find(ext => ext.valueString)) === null || _d === void 0 ? void 0 : _d.valueString) !== null && _e !== void 0 ? _e : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Lab", sourceDocument: {
            id: (_f = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.id) !== null && _f !== void 0 ? _f : "",
            fileName: valueString,
        } }, { children: _jsx(LabDisplay, { lab: selectedLab, tableRow: rowContents }) })));
}
function LabDisplay({ lab, tableRow }) {
    if (!lab) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: lab.id,
                    observation: tableRow.observation,
                    interpretation: tableRow.interpretation,
                    referenceRange: tableRow.referenceRange,
                } }), _jsx(PeriodDisplay, { period: lab.effectivePeriod, fallbackDate: lab === null || lab === void 0 ? void 0 : lab.effectiveDateTime }), _jsx(CodesDisplay, { code: lab.code })] }));
}
