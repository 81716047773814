import { jsx as _jsx } from "react/jsx-runtime";
import posthog from "posthog-js";
import { createContext, useContext } from "react";
posthog.init(process.env.REACT_APP_PUBLIC_POST_HOG_KEY ? process.env.REACT_APP_PUBLIC_POST_HOG_KEY : "", {
    api_host: process.env.REACT_APP_PUBLIC_POST_HOG_HOST,
    autocapture: false,
    session_recording: {
        maskAllInputs: true,
    },
});
export var Features;
(function (Features) {
    Features["medicalRequest"] = "medical request";
    Features["organization"] = "organization";
    Features["facility"] = "facility";
    Features["patient"] = "patient(s)";
    Features["link"] = "link";
    Features["document"] = "document(s)";
    Features["fhir"] = "fhir";
    Features["subscription"] = "subscription";
    Features["sandbox"] = "sandbox";
    Features["invoices"] = "invoices";
    Features["webhookUrl"] = "webhook url";
    Features["user"] = "user";
    Features["form"] = "form";
    Features["apiKeyId"] = "api key";
    Features["clientApiKeyId"] = "client api key";
    Features["patientMatches"] = "patient matches";
})(Features || (Features = {}));
export var Actions;
(function (Actions) {
    Actions["get"] = "get";
    Actions["create"] = "create";
    Actions["update"] = "update";
    Actions["delete"] = "delete";
    Actions["remove"] = "remove";
    Actions["download"] = "download";
    Actions["open"] = "open";
    Actions["close"] = "close";
    Actions["submit"] = "submit";
    Actions["revoke"] = "revoke";
    Actions["generate"] = "generate";
    Actions["test"] = "test";
    Actions["retry"] = "retry";
    Actions["signIn"] = "sign-in";
    Actions["signUp"] = "sign-up";
    Actions["cancel"] = "cancel";
    Actions["toggle"] = "toggle";
    Actions["view"] = "view";
    Actions["navigate"] = "navigate";
    Actions["manage"] = "manage";
    Actions["search"] = "search";
})(Actions || (Actions = {}));
const defaultContext = {
    emit: () => undefined,
    identify: () => undefined,
    group: () => undefined,
    reset: () => undefined,
};
export const AnalyticsContext = createContext(defaultContext);
const POSTHOG_GROUP_ID = "customer";
export const AnalyticsProvider = ({ enabled = true, children, }) => {
    return (_jsx(AnalyticsContext.Provider, Object.assign({ value: !enabled
            ? defaultContext
            : {
                emit: (eventAction, eventFeature, properties) => {
                    const event = `${eventAction} ${eventFeature}`;
                    if (event) {
                        posthog.capture(event, Object.assign(Object.assign({}, properties), { platform: "dashboard" }));
                    }
                },
                identify: (id, options) => {
                    posthog.identify(id, options);
                },
                group: (cxId, options) => {
                    posthog.group(POSTHOG_GROUP_ID, cxId, options);
                },
                reset: () => {
                    posthog.reset();
                },
            } }, { children: children })));
};
export const useAnalyticsContext = () => useContext(AnalyticsContext);
