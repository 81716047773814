import { z } from "zod";
export const addressSchema = z.object({
    addressLine1: z.string().min(1),
    addressLine2: z.string().optional().nullable(),
    city: z.string().min(1),
    state: z.string().min(1),
    zip: z.string().length(5),
    country: z.string().min(1).default("USA"),
});
export const nonStrictAddressSchema = z.object({
    addressLine1: z.string().optional().nullable(),
    addressLine2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
});
