import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, Box, Stack, Radio, RadioGroup, Flex, } from "@chakra-ui/react";
import { useState } from "react";
import { Button } from "./button";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { DateRange } from "../../shared/form/DateRange";
export function MrModal({ isOpen, isDownloading, existingMrStatus, actions, }) {
    var _a;
    const [newDocument, setNewDocument] = useState("new");
    const mrDateString = (_a = existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.htmlCreatedAt) !== null && _a !== void 0 ? _a : existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.pdfCreatedAt;
    const previousMrDate = mrDateString ? new Date(mrDateString).toLocaleString("en-US") : undefined;
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: actions.onCloseModal, size: "3xl" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ px: 6, py: 4 }, { children: [_jsx(ModalCloseButton, {}), _jsxs(Box, { children: [_jsx(Text, Object.assign({ mb: 1, fontSize: "xl", fontWeight: "bold" }, { children: "Medical Record Summary" })), _jsx(Text, Object.assign({ color: "gray.400", mb: 3 }, { children: generateOutput(previousMrDate) })), _jsxs(Stack, Object.assign({ spacing: 4 }, { children: [_jsx(RadioGroup, Object.assign({ onChange: setNewDocument, value: newDocument }, { children: _jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Radio, Object.assign({ borderColor: METRIPORT_PRIMARY, value: "new" }, { children: "Generate New" })), ((existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.htmlCreatedAt) || (existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.pdfCreatedAt)) && (_jsxs(Radio, Object.assign({ borderColor: METRIPORT_PRIMARY, value: "existing" }, { children: ["Get Summary from ", previousMrDate] })))] })) })), _jsx(DownloadActions, { existingMrStatus: existingMrStatus, isDownloading: isDownloading, downloadExistingMr: newDocument === "new", actions: actions })] }))] })] }))] })));
}
function generateOutput(previousMrDate) {
    return `Generate a new Medical Record Summary${previousMrDate ? ", or instantly download an existing one." : ""}`;
}
function DownloadActions({ existingMrStatus, isDownloading, downloadExistingMr, actions, }) {
    var _a, _b;
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const pdfDisabled = !downloadExistingMr && !(existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.pdfCreatedAt);
    const htmlDisabled = !downloadExistingMr && !(existingMrStatus === null || existingMrStatus === void 0 ? void 0 : existingMrStatus.htmlCreatedAt);
    return (_jsxs(Flex, Object.assign({ alignItems: "end" }, { children: [downloadExistingMr && (_jsx(DateRange, { id: "mr-date-range", label: "Filter by date", dateFrom: dateFrom, dateTo: dateTo, onSetToDate: (to) => setDateTo(to), onSetFromDate: (from) => setDateFrom(from) })), _jsxs(Box, Object.assign({ pl: 3 }, { children: [_jsx(Button, Object.assign({ isLoading: (_a = isDownloading.pdf) === null || _a === void 0 ? void 0 : _a.downloading, disabled: pdfDisabled, onClick: () => {
                            actions.onCloseModal();
                            actions.onDownloadMR(downloadExistingMr, "pdf", dateTo, dateFrom);
                        }, mr: 3 }, { children: "PDF" })), _jsx(Button, Object.assign({ isLoading: (_b = isDownloading.html) === null || _b === void 0 ? void 0 : _b.downloading, disabled: htmlDisabled, onClick: () => {
                            actions.onCloseModal();
                            actions.onDownloadMR(downloadExistingMr, "html", dateTo, dateFrom);
                        }, mr: 3 }, { children: "HTML" })), downloadExistingMr && (_jsx(Button, Object.assign({ style: { backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }, variant: "outline", onClick: () => {
                            setDateFrom("");
                            setDateTo("");
                        } }, { children: "Clear" })))] }))] })));
}
