var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { z } from "zod";
import { apiOSS as api } from "./apiOSS";
export const mrFilterSchema = z.object({
    key: z.string(),
    dateFilter: z
        .object({
        from: z.string().optional(),
        to: z.string().optional(),
    })
        .optional(),
    stringFilter: z.string().optional(),
});
export const settingsSchema = z.object({
    id: z.string(),
    webhookUrl: z.string().nullable(),
    webhookKey: z.string().nullable(),
    mrFilters: z.array(mrFilterSchema).nullish(),
});
const SETTINGS_URL = `/settings`;
export function getSettings() {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield api.get(SETTINGS_URL);
        return resp.data ? settingsSchema.parse(resp.data) : null;
    });
}
export const settingsUpdateSchema = z.object({
    webhookUrl: z.string().url().optional().or(z.literal("")),
});
export function updateSettings(payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield api.post(SETTINGS_URL, payload);
        if (!resp.data)
            throw new Error(`Update didn't return Settings`);
        return settingsSchema.parse(resp.data);
    });
}
