var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { SidePanelContent } from "../../side-panel-content";
import { formatDate, getResourcesFromBundle } from "../shared";
import { DetailsDisplay } from "../shared/details";
import { SidePanelSection } from "../shared/sidepanel-section";
import { VitalsChart } from "./chart";
import { getVital, groupVitals, renderBpValue } from "./table-data";
const CHART_DATAPOINTS = 5;
export function VitalContent(_a) {
    var _b, _c, _d, _e, _f;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const observations = getResourcesFromBundle(mappedConsolidated, "Observation");
    const vitals = getVital(observations);
    const groupedVitals = groupVitals(vitals);
    const selectedVital = groupedVitals.find(vital => vital.mostRecentObservation.id === rowContents.id);
    if (!selectedVital) {
        return null;
    }
    if (selectedVital.title === "Blood Pressure") {
        return buildBloodPressurePanel(selectedVital, rowContents);
    }
    const mostRecentVital = selectedVital.mostRecentObservation;
    const valueString = (_d = (_c = (_b = mostRecentVital === null || mostRecentVital === void 0 ? void 0 : mostRecentVital.extension) === null || _b === void 0 ? void 0 : _b.find(ext => ext.valueString)) === null || _c === void 0 ? void 0 : _c.valueString) !== null && _d !== void 0 ? _d : "";
    let vitalObservations = undefined;
    let dates = undefined;
    if (selectedVital.sortedPoints) {
        vitalObservations = selectedVital.sortedPoints.map(p => p.value);
        dates = selectedVital.sortedPoints.map(p => p.date);
    }
    return (_jsxs(SidePanelContent, Object.assign({ title: selectedVital.title, sourceDocument: {
            id: (_e = mostRecentVital === null || mostRecentVital === void 0 ? void 0 : mostRecentVital.id) !== null && _e !== void 0 ? _e : "",
            fileName: valueString,
        } }, { children: [vitalObservations && vitalObservations.length > 1 && dates && (_jsx(_Fragment, { children: _jsx(VitalsChart, { vitalsId: (_f = mostRecentVital.id) !== null && _f !== void 0 ? _f : "", vitals: {
                        title: selectedVital.title,
                        values: vitalObservations.slice(Math.max(0, vitalObservations.length - CHART_DATAPOINTS), vitalObservations.length),
                    }, dates: dates
                        .slice(Math.max(0, dates.length - CHART_DATAPOINTS), dates.length)
                        .map(formatDate) }) })), _jsx(VitalDisplay, { vital: selectedVital, tableRow: rowContents })] })));
}
function buildBloodPressurePanel(selectedVital, rowContents) {
    var _a, _b, _c, _d, _e;
    const mostRecentVital = selectedVital.mostRecentObservation;
    const valueString = (_c = (_b = (_a = mostRecentVital === null || mostRecentVital === void 0 ? void 0 : mostRecentVital.extension) === null || _a === void 0 ? void 0 : _a.find(ext => ext.valueString)) === null || _b === void 0 ? void 0 : _b.valueString) !== null && _c !== void 0 ? _c : "";
    let systolic = undefined;
    let diastolic = undefined;
    let dates = undefined;
    if (selectedVital.sortedPoints) {
        systolic = selectedVital.sortedPoints.flatMap(p => { var _a; return ((_a = p.bp) === null || _a === void 0 ? void 0 : _a.systolic) || []; });
        diastolic = selectedVital.sortedPoints.flatMap(p => { var _a; return ((_a = p.bp) === null || _a === void 0 ? void 0 : _a.diastolic) || []; });
        dates = selectedVital.sortedPoints.map(p => p.date);
    }
    return (_jsxs(SidePanelContent, Object.assign({ title: selectedVital.title, sourceDocument: {
            id: (_d = mostRecentVital === null || mostRecentVital === void 0 ? void 0 : mostRecentVital.id) !== null && _d !== void 0 ? _d : "",
            fileName: valueString,
        } }, { children: [systolic && diastolic && dates && (_jsx(_Fragment, { children: _jsx(VitalsChart, { vitalsId: (_e = mostRecentVital.id) !== null && _e !== void 0 ? _e : "", vitals: {
                        title: "Systolic",
                        values: systolic.slice(Math.max(0, systolic.length - CHART_DATAPOINTS), systolic.length),
                    }, vitalsSecondary: {
                        title: "Diastolic",
                        values: diastolic.slice(Math.max(0, diastolic.length - CHART_DATAPOINTS), diastolic.length),
                    }, dates: dates
                        .slice(Math.max(0, dates.length - CHART_DATAPOINTS), dates.length)
                        .map(formatDate) }) })), _jsx(VitalDisplay, { vital: selectedVital, tableRow: rowContents })] })));
}
function VitalDisplay({ vital, tableRow, }) {
    if (!vital)
        return null;
    const pointsDescOrder = [...(vital.sortedPoints || [])].reverse();
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: vital.mostRecentObservation.id,
                    measurement: tableRow.observation,
                } }), pointsDescOrder && (_jsx(_Fragment, { children: _jsx(SidePanelSection, Object.assign({ title: "Data Points" }, { children: pointsDescOrder.slice(0, 10).map((p, index) => (_jsx(Text, Object.assign({ fontWeight: index === 0 ? "bold" : "normal" }, { children: p.bp
                            ? `• ${formatDate(p.date)} - ${renderBpValue(p)}`
                            : p.unit
                                ? `• ${formatDate(p.date)} - ${p.value} ${p.unit}`
                                : `• ${formatDate(p.date)} - ${p.value}` })))) })) }))] }));
}
