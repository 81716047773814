var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Input as ChakraInput, Text, VStack, } from "@chakra-ui/react";
import React from "react";
export const DateRange = React.forwardRef((_a, ref) => {
    var _b;
    var { onSetToDate, onSetFromDate, dateFrom, dateTo, error, disabled } = _a, rest = __rest(_a, ["onSetToDate", "onSetFromDate", "dateFrom", "dateTo", "error", "disabled"]);
    return (_jsxs(VStack, Object.assign({ flex: 1, w: "100%", alignItems: "initial" }, { children: [_jsxs(HStack, Object.assign({ flex: 1, w: "100%", spacing: 2 }, { children: [_jsxs(VStack, Object.assign({ flex: 1, alignItems: "flex-start" }, { children: [_jsx(Text, Object.assign({ m: 0 }, { children: "From:" })), _jsx(ChakraInput, Object.assign({ value: dateFrom, onChange: e => onSetFromDate(e.target.value), disabled: disabled, ref: ref, isInvalid: !!error }, rest, { id: `${rest.id}_from`, type: "date" }))] })), _jsxs(VStack, Object.assign({ flex: 1, alignItems: "flex-start" }, { children: [_jsx(Text, Object.assign({ m: 0 }, { children: "To:" })), _jsx(ChakraInput, Object.assign({ value: dateTo, onChange: e => onSetToDate(e.target.value), disabled: disabled, ref: ref, isInvalid: !!error }, rest, { id: `${rest.id}_to`, type: "date" }))] }))] })), !!error && _jsx(Text, Object.assign({ mt: 2 }, { children: (_b = error.message) === null || _b === void 0 ? void 0 : _b.toString() }))] })));
});
