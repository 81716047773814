import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { getFirstCodeSpecified, RX_NORM_CODE, compare, filterByDate } from "../shared";
import { getResourcesFromBundle } from "../shared";
import { getValidCode } from "../shared";
export const immunizationTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        {
            field: "immunization",
        },
        { field: "code" },
        { field: "manufacturer" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        { field: "status" },
    ];
    const immunizations = getResourcesFromBundle(bundle, "Immunization");
    const groupedImmunizations = groupImmunizations(immunizations);
    return {
        columnDefs,
        rowData: getImmunizationRowData({ immunizations: groupedImmunizations, tableFilters }),
    };
};
export function groupImmunizations(imms) {
    const results = [];
    const immsMap = new Map();
    imms.map(imm => {
        var _a;
        const code = getImmunizationsCode(imm);
        const title = getImmunizationsDisplay(imm);
        const date = getImmunizationDate(imm);
        const status = (_a = imm.status) !== null && _a !== void 0 ? _a : "-";
        const newEntry = {
            rawImmunization: imm,
            date,
            status,
        };
        if (!code) {
            results.push({
                title,
                code: "-",
                mostRecentImmunization: newEntry,
                sortedOccurrences: [newEntry],
                status,
            });
            return;
        }
        const existing = immsMap.get(code);
        if (existing) {
            immsMap.set(code, [...existing, newEntry]);
        }
        else {
            immsMap.set(code, [newEntry]);
        }
    });
    [...immsMap.entries()].map(([code, values]) => {
        var _a, _b;
        const sortedOccurrences = values.sort((a, b) => {
            const dateA = a.date ? new Date(a.date).getTime() : 0;
            const dateB = b.date ? new Date(b.date).getTime() : 0;
            return dateB - dateA;
        });
        const mostRecent = (_a = sortedOccurrences.find(o => { var _a; return ((_a = o.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "not-done"; })) !== null && _a !== void 0 ? _a : sortedOccurrences[0];
        if (!mostRecent)
            return;
        results.push({
            code,
            title: getImmunizationsDisplay(mostRecent.rawImmunization),
            mostRecentImmunization: mostRecent,
            sortedOccurrences,
            status: (_b = mostRecent.status) !== null && _b !== void 0 ? _b : "-",
        });
    });
    return results;
}
function getImmunizationRowData({ immunizations, tableFilters, }) {
    return immunizations === null || immunizations === void 0 ? void 0 : immunizations.map(immunization => {
        var _a, _b, _c, _d;
        return ({
            id: (_a = immunization.mostRecentImmunization.rawImmunization.id) !== null && _a !== void 0 ? _a : "-",
            immunization: immunization.title,
            code: immunization.code,
            manufacturer: (_c = (_b = immunization.mostRecentImmunization.rawImmunization.manufacturer) === null || _b === void 0 ? void 0 : _b.display) !== null && _c !== void 0 ? _c : "-",
            date: (_d = immunization.mostRecentImmunization.date) !== null && _d !== void 0 ? _d : "-",
            status: immunization.status,
        });
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getImmunizationsDisplay(immunization) {
    var _a, _b;
    const codings = getValidCode((_a = immunization.vaccineCode) === null || _a === void 0 ? void 0 : _a.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
        return displays.join(", ");
    }
    else if ((_b = immunization.vaccineCode) === null || _b === void 0 ? void 0 : _b.text) {
        return immunization.vaccineCode.text;
    }
    return "-";
}
function getImmunizationsCode(immunization) {
    var _a;
    const coding = getFirstCodeSpecified((_a = immunization.vaccineCode) === null || _a === void 0 ? void 0 : _a.coding, ["cvx", RX_NORM_CODE]);
    return coding ? `${coding.system}: ${coding.code}` : undefined;
}
function getImmunizationDate(immunizations) {
    return dayjs(immunizations.occurrenceDateTime).format(ISO_DATE);
}
