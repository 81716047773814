export var SharedStateActionType;
(function (SharedStateActionType) {
    SharedStateActionType[SharedStateActionType["set"] = 0] = "set";
})(SharedStateActionType || (SharedStateActionType = {}));
export const reducer = (state, action) => {
    switch (action.type) {
        case SharedStateActionType.set: {
            return Object.assign(Object.assign({}, state), { authToken: action.authToken, baseUrl: action.baseUrl, ehrDetails: action.ehrDetails, isLoaded: action.isLoaded });
        }
        default:
            return state;
    }
};
export const initialState = {};
