export var PatientConsolidatedStateActionType;
(function (PatientConsolidatedStateActionType) {
    PatientConsolidatedStateActionType[PatientConsolidatedStateActionType["setPatientsDocuments"] = 0] = "setPatientsDocuments";
    PatientConsolidatedStateActionType[PatientConsolidatedStateActionType["setPatientsConsolidated"] = 1] = "setPatientsConsolidated";
})(PatientConsolidatedStateActionType || (PatientConsolidatedStateActionType = {}));
export const reducer = (state, action) => {
    var _a;
    switch (action.type) {
        case PatientConsolidatedStateActionType.setPatientsConsolidated: {
            const mappedResources = {};
            (_a = action.consolidatedBundle.entry) === null || _a === void 0 ? void 0 : _a.forEach(entry => {
                const resource = entry.resource;
                if (resource) {
                    if (!mappedResources[resource.resourceType]) {
                        mappedResources[resource.resourceType] = {};
                    }
                    const mappedResource = mappedResources[resource.resourceType];
                    if (mappedResource && resource.id) {
                        mappedResource[resource.id] = resource;
                    }
                }
            });
            return Object.assign(Object.assign({}, state), { [action.patientId]: Object.assign(Object.assign({}, state[action.patientId]), { consolidated: mappedResources }) });
        }
        case PatientConsolidatedStateActionType.setPatientsDocuments: {
            return Object.assign(Object.assign({}, state), { [action.patientId]: Object.assign(Object.assign({}, state[action.patientId]), { documents: action.documents }) });
        }
        default:
            return state;
    }
};
export const initialState = {};
