import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle, getValidCode } from "../shared";
export const observationTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "observation" },
        { field: "value" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    const observations = getResourcesFromBundle(bundle, "Observation");
    const otherObservations = getOtherObservations(observations);
    return {
        columnDefs,
        rowData: getOtherObservationsRowData({ observations: otherObservations, tableFilters }),
    };
};
function getOtherObservations(observations) {
    var _a;
    const otherObservations = [];
    for (const observation of observations) {
        const isObservations = (_a = observation === null || observation === void 0 ? void 0 : observation.category) === null || _a === void 0 ? void 0 : _a.find(ext => {
            var _a, _b, _c;
            const code = (_c = (_b = (_a = ext.coding) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code) === null || _c === void 0 ? void 0 : _c.toLowerCase();
            return code !== "vital-signs" && code !== "laboratory" && code !== "social-history";
        });
        if (isObservations) {
            otherObservations.push(observation);
        }
    }
    return otherObservations;
}
function getOtherObservationsRowData({ observations, tableFilters, }) {
    return observations === null || observations === void 0 ? void 0 : observations.map(observation => {
        var _a;
        return ({
            id: (_a = observation.id) !== null && _a !== void 0 ? _a : "-",
            observation: getObservationsDisplay(observation),
            value: renderObservationsValue(observation),
            date: getSocialHistoryDate(observation),
        });
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getObservationsDisplay(observations) {
    var _a, _b;
    const codings = getValidCode((_a = observations.code) === null || _a === void 0 ? void 0 : _a.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
        return displays.join(", ");
    }
    else if ((_b = observations.code) === null || _b === void 0 ? void 0 : _b.text) {
        return observations.code.text;
    }
    return "-";
}
function renderObservationsValue(observations) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (observations.valueQuantity) {
        const value = (_a = observations.valueQuantity) === null || _a === void 0 ? void 0 : _a.value;
        const unit = (_c = (_b = observations.valueQuantity) === null || _b === void 0 ? void 0 : _b.unit) === null || _c === void 0 ? void 0 : _c.replace(/[{()}]/g, "");
        return `${value} ${unit}`;
    }
    else if (observations.valueCodeableConcept) {
        return ((_g = (_e = (_d = observations.valueCodeableConcept) === null || _d === void 0 ? void 0 : _d.text) !== null && _e !== void 0 ? _e : (_f = getValidCode(observations.valueCodeableConcept.coding)[0]) === null || _f === void 0 ? void 0 : _f.display) !== null && _g !== void 0 ? _g : "-");
    }
    else {
        return "-";
    }
}
function getSocialHistoryDate(observations) {
    return dayjs(observations.effectiveDateTime).format(ISO_DATE);
}
