var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SidePanelContent } from "../../side-panel-content";
import { PeriodDisplay } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
export function AllergyContent(_a) {
    var _b, _c, _d, _e, _f;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const selectedAllergy = (_b = mappedConsolidated === null || mappedConsolidated === void 0 ? void 0 : mappedConsolidated["AllergyIntolerance"]) === null || _b === void 0 ? void 0 : _b[rowContents.id];
    const valueString = (_e = (_d = (_c = selectedAllergy === null || selectedAllergy === void 0 ? void 0 : selectedAllergy.extension) === null || _c === void 0 ? void 0 : _c.find(ext => ext.valueString)) === null || _d === void 0 ? void 0 : _d.valueString) !== null && _e !== void 0 ? _e : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Allergy", sourceDocument: {
            id: (_f = selectedAllergy === null || selectedAllergy === void 0 ? void 0 : selectedAllergy.id) !== null && _f !== void 0 ? _f : "",
            fileName: valueString,
        } }, { children: _jsx(AllergyDisplay, { allergy: selectedAllergy, tableRow: rowContents }) })));
}
function AllergyDisplay({ allergy, tableRow, }) {
    var _a;
    if (!allergy) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: allergy.id,
                    allergy: tableRow.allergy,
                    manifestation: tableRow.manifestation,
                    status: tableRow.status,
                    criticality: allergy.criticality,
                } }), _jsx(PeriodDisplay, { period: allergy === null || allergy === void 0 ? void 0 : allergy.onsetPeriod, fallbackDate: allergy === null || allergy === void 0 ? void 0 : allergy.onsetDateTime }), _jsx(CodesDisplay, { code: (_a = allergy.reaction) === null || _a === void 0 ? void 0 : _a.flatMap(r => r.substance || []) })] }));
}
