import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useColorModeValue, useBreakpointValue, IconButton, Flex } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
export function SidePanel({ showPanel, setSidePanelContent, children, }) {
    const bg = useColorModeValue("white", "gray.800");
    const useModal = useBreakpointValue({
        base: true,
        "2xl": false,
    });
    if (useModal) {
        return (_jsxs(Modal, Object.assign({ size: "2xl", isOpen: showPanel, onClose: setSidePanelContent }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ overflow: "auto", height: "calc(100vh - 100px)", mx: 8, bg: bg, borderRadius: 10, border: "solid", borderColor: useColorModeValue("gray.200", "gray.700"), py: 4, px: 4 }, { children: [_jsx(ModalActions, { setSidePanelContent: setSidePanelContent }), children] }))] })));
    }
    return (_jsxs(Box, Object.assign({ overflow: "auto", hidden: !showPanel, height: "calc(100vh - 100px)", top: 50 + 25, position: "sticky", ml: 6, bg: bg, width: "30vw", borderRadius: 10, border: "solid", borderColor: useColorModeValue("gray.200", "gray.700"), py: 4, px: 8 }, { children: [_jsx(ModalActions, { setSidePanelContent: setSidePanelContent }), children] })));
}
function ModalActions({ setSidePanelContent }) {
    return (_jsx(Flex, Object.assign({ flexDirection: "row", mb: 2, justifyContent: "flex-end" }, { children: _jsx(IconButton, { background: "transparent", "aria-label": "Close side panel", icon: _jsx(RiCloseLine, {}), onClick: setSidePanelContent }) })));
}
