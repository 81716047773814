var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { SidePanelContent } from "../../side-panel-content";
import { formatDate, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { SidePanelSection } from "../shared/sidepanel-section";
import { groupImmunizations } from "./table-data";
export function ImmunizationContent(_a) {
    var _b, _c, _d, _e;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const immunizations = getResourcesFromBundle(mappedConsolidated, "Immunization");
    const groupedImmunizations = groupImmunizations(immunizations);
    const selectedImmunization = groupedImmunizations.find(immunizations => immunizations.mostRecentImmunization.rawImmunization.id === rowContents.id);
    if (!selectedImmunization) {
        return null;
    }
    const mostRecentImmunization = selectedImmunization.mostRecentImmunization;
    const sourceFileName = (_d = (_c = (_b = mostRecentImmunization === null || mostRecentImmunization === void 0 ? void 0 : mostRecentImmunization.rawImmunization.extension) === null || _b === void 0 ? void 0 : _b.find(ext => ext.valueString)) === null || _c === void 0 ? void 0 : _c.valueString) !== null && _d !== void 0 ? _d : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Immunization", sourceDocument: {
            id: (_e = mostRecentImmunization === null || mostRecentImmunization === void 0 ? void 0 : mostRecentImmunization.rawImmunization.id) !== null && _e !== void 0 ? _e : "",
            fileName: sourceFileName,
        } }, { children: _jsx(ImmunizationDisplay, { immunization: selectedImmunization, tableRow: rowContents }) })));
}
function ImmunizationDisplay({ immunization, tableRow, }) {
    if (!immunization) {
        return null;
    }
    const mostRecent = immunization.mostRecentImmunization;
    const doseQuantity = mostRecent.rawImmunization.doseQuantity;
    const hasDoseQuantity = (doseQuantity === null || doseQuantity === void 0 ? void 0 : doseQuantity.value) && (doseQuantity === null || doseQuantity === void 0 ? void 0 : doseQuantity.unit);
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: mostRecent.rawImmunization.id,
                    immunization: tableRow.immunization,
                    date: immunization.mostRecentImmunization.date,
                    manufacturer: tableRow.manufacturer,
                    lotNumber: mostRecent.rawImmunization.lotNumber,
                    dose: hasDoseQuantity ? `${doseQuantity === null || doseQuantity === void 0 ? void 0 : doseQuantity.value} ${doseQuantity === null || doseQuantity === void 0 ? void 0 : doseQuantity.unit}` : "-",
                    status: immunization.status,
                } }), immunization.sortedOccurrences && immunization.sortedOccurrences.length > 1 && (_jsx(_Fragment, { children: _jsx(SidePanelSection, Object.assign({ title: "Instances" }, { children: immunization.sortedOccurrences.map((p, index) => (_jsx(Text, Object.assign({ fontWeight: index === 0 ? "bold" : "normal" }, { children: `• ${formatDate(p.date)} - ${p.status}` })))) })) })), _jsx(CodesDisplay, { code: mostRecent.rawImmunization.vaccineCode })] }));
}
