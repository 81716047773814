import { jsx as _jsx } from "react/jsx-runtime";
import { ISO_DATE } from "@metriport/shared/common/date";
import { compare, filterByDate } from "../shared";
import { getResourcesFromBundle } from "../shared";
import { DocumentMenu } from "./document-menu";
import { bytesToSize } from "../../../../../shared/util";
import { useDownloadFile } from "../../../shared-logic/useDownloadFile";
import { formatDate } from "../../../../shared/date";
const METRIPORT_CODE = "METRIPORT";
export function buildDocumentTableData({ bundle, tableFilters, noActions = true, }) {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "fileName", hide: true },
        { field: "description" },
        { field: "size" },
        { field: "fileType" },
        { field: "organization" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        ...(noActions
            ? []
            : [
                {
                    field: "actions",
                    minWidth: 350,
                    onCellClicked: () => null,
                    cellRenderer: (props) => {
                        const { onDownloadFile, isDownloading } = useDownloadFile();
                        return (_jsx(DocumentMenu, { contentType: props.data.fileType, docId: props.data.id, fileName: props.data.fileName, onDownloadDocument: onDownloadFile, isDownloading: isDownloading }));
                    },
                },
            ]),
    ];
    const documents = getResourcesFromBundle(bundle, "DocumentReference");
    return {
        columnDefs,
        rowData: getDocumentRowData({ documents, tableFilters }),
    };
}
function getDocumentRowData({ documents, tableFilters, }) {
    return documents === null || documents === void 0 ? void 0 : documents.map(documentReference => {
        var _a, _b, _c, _d;
        const metriportContent = getMetriportContent(documentReference);
        const contentType = (_a = metriportContent === null || metriportContent === void 0 ? void 0 : metriportContent.attachment) === null || _a === void 0 ? void 0 : _a.contentType;
        const fileName = (_b = metriportContent === null || metriportContent === void 0 ? void 0 : metriportContent.attachment) === null || _b === void 0 ? void 0 : _b.title;
        return {
            id: (_c = documentReference.id) !== null && _c !== void 0 ? _c : "-",
            fileName: fileName !== null && fileName !== void 0 ? fileName : "-",
            description: (_d = documentReference.description) !== null && _d !== void 0 ? _d : "-",
            size: getDocumentSize(documentReference),
            fileType: contentType !== null && contentType !== void 0 ? contentType : "-",
            organization: getOrganizationName(documentReference),
            date: documentReference.date ? formatDate(documentReference.date, ISO_DATE) : "-",
        };
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
export const getDocumentSize = (doc) => {
    var _a;
    if (doc.content) {
        const metriportContent = doc.content.find(isMetriportContent);
        if ((_a = metriportContent === null || metriportContent === void 0 ? void 0 : metriportContent.attachment) === null || _a === void 0 ? void 0 : _a.size) {
            return bytesToSize(metriportContent.attachment.size);
        }
        return "-";
    }
    return "-";
};
export function getMetriportContent(doc) {
    if (!doc || !doc.content)
        return undefined;
    const contents = doc.content.filter(isMetriportContent);
    return contents[0];
}
function isMetriportContent(content) {
    var _a;
    return !!((_a = content.extension) === null || _a === void 0 ? void 0 : _a.some(ext => { var _a; return ((_a = ext.valueCoding) === null || _a === void 0 ? void 0 : _a.code) === METRIPORT_CODE; }));
}
export const getOrganizationName = (doc) => {
    if (doc.contained) {
        const org = doc.contained.flatMap(c => (c.resourceType === "Organization" ? c : []))[0];
        if (org === null || org === void 0 ? void 0 : org.name)
            return org.name;
        return "-";
    }
    return "-";
};
