import { compare } from "../shared";
import { getResourcesFromBundle } from "../shared";
export const relatedPersonTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "name" },
        { field: "relationships" },
        { field: "contacts" },
        { field: "addresses" },
    ];
    const relatedPersons = getResourcesFromBundle(bundle, "RelatedPerson");
    return {
        columnDefs,
        rowData: getRelatedPersonRowData({ relatedPersons, tableFilters }),
    };
};
function getRelatedPersonRowData({ relatedPersons, tableFilters, }) {
    return relatedPersons === null || relatedPersons === void 0 ? void 0 : relatedPersons.map(relatedPerson => {
        var _a, _b;
        return ({
            id: (_a = relatedPerson.id) !== null && _a !== void 0 ? _a : "-",
            name: getRelatedPersonsName(relatedPerson),
            relationships: getRelatedPersonsRelationships(relatedPerson),
            contacts: renderRelatedPersonContacts(relatedPerson),
            addresses: (_b = renderRelatedPersonAddresses(relatedPerson)) !== null && _b !== void 0 ? _b : "-",
        });
    }).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getRelatedPersonsName(relatedPerson) {
    const names = [];
    if (relatedPerson.name) {
        for (const name of relatedPerson.name) {
            if (name.text) {
                names.push(name.text);
            }
            else {
                const parts = [];
                if (name.prefix) {
                    parts.push(name.prefix.join(" "));
                }
                if (name.given) {
                    parts.push(name.given.join(" "));
                }
                if (name.family) {
                    parts.push(name.family);
                }
                names.push(parts.join(" "));
            }
        }
    }
    return names.join(", ");
}
function getRelatedPersonsRelationships(relatedPerson) {
    const relationships = [];
    if (relatedPerson.relationship) {
        for (const relationship of relatedPerson.relationship) {
            if (relationship.coding) {
                for (const coding of relationship.coding) {
                    if (coding.display) {
                        relationships.push(coding.display);
                    }
                }
            }
        }
    }
    return relationships.join(", ");
}
function renderRelatedPersonContacts(relatedPerson) {
    var _a, _b;
    const contacts = (_a = relatedPerson.telecom) === null || _a === void 0 ? void 0 : _a.map(telecom => {
        return `${telecom.system}${telecom.use ? `- ${telecom.use}` : ""}: ${telecom.value}`;
    });
    return (_b = contacts === null || contacts === void 0 ? void 0 : contacts.join(", ")) !== null && _b !== void 0 ? _b : "-";
}
function renderRelatedPersonAddresses(relatedPerson) {
    var _a, _b;
    const addresses = (_a = relatedPerson.address) === null || _a === void 0 ? void 0 : _a.map(address => {
        var _a;
        return `${(_a = address.line) === null || _a === void 0 ? void 0 : _a.join(", ")} ${address.city}, ${address.state} ${address.postalCode}`;
    });
    return (_b = addresses === null || addresses === void 0 ? void 0 : addresses.join(", ")) !== null && _b !== void 0 ? _b : "-";
}
