import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DemographicsModal } from "../shared-components/demos-modal";
import { PatientFHIRData } from "../shared-components/fhir-sections";
import { fhirSections } from "../shared-components/fhir-sections/sections";
import { PatientsHeader } from "../shared-components/header";
import { MrModal } from "../shared-components/mr-modal";
import { PatientForm } from "../shared-components/patient-form";
import { PatientMatches } from "../shared-components/patient-matches";
import { DocumentProgressContainer } from "../shared-components/progress-bar";
import { RawDocuments } from "../shared-components/raw-documents";
import { PatientTabs } from "../shared-components/tabs";
import { usePatientConsolidatedContext } from "../shared-logic/consolidated-context";
import { PatientConsolidatedStateActionType, } from "../shared-logic/consolidated-context/reducer";
import { useSharedContext } from "../shared-logic/shared-context";
import { useGetConsolidated } from "../shared-logic/useGetConsolidated";
import { useGetFacilities } from "../shared-logic/useGetFacilties";
import { useGetFilters } from "../shared-logic/useGetFilters";
import { useGetMedicalRecord } from "../shared-logic/useGetMedicalRecord";
import { useGetPatientInfo } from "../shared-logic/useGetPatientInfo";
import { useGetPatientMatches } from "../shared-logic/useGetPatientMatches";
import { useQueryDocuments } from "../shared-logic/useQueryDocuments";
import { useSubmitPatient } from "../shared-logic/useSubmitPatient";
const tabs = ["fhir", "documents"];
export function PatientOverview({ patientId, selectedResourceParams, }) {
    var _a, _b;
    const patientFormDisclosure = useDisclosure();
    const patientMRDisclosure = useDisclosure();
    const patientDemosDisclosure = useDisclosure();
    const patientMatchesDisclosure = useDisclosure();
    const { sharedState } = useSharedContext();
    const { patientConsolidatedDispatch, patientConsolidatedState } = usePatientConsolidatedContext();
    const { createOrUpdatePatient, isSubmitting } = useSubmitPatient({
        patientId,
        onComplete: () => patientFormDisclosure.onClose(),
    });
    const { patient, isFetchingPatient } = useGetPatientInfo({ patientId });
    const { filters } = useGetFilters();
    const { facilities } = sharedState.ehrDetails ? { facilities: [] } : useGetFacilities();
    const { existingMrStatus, isMrDownloading, onDownloadMR } = useGetMedicalRecord({ patientId });
    const { patientMatches } = sharedState.ehrDetails
        ? { patientMatches: null }
        : useGetPatientMatches({ patientId });
    const documentsState = (_a = patientConsolidatedState[patientId]) === null || _a === void 0 ? void 0 : _a.documents;
    const consolidatedState = (_b = patientConsolidatedState[patientId]) === null || _b === void 0 ? void 0 : _b.consolidated;
    const { isQueryingDocuments, onQueryPatientDocuments, onSearchPatientDocuments, downloadProgress, lastDocQuery, documentListResult, searchDocFilters, } = useQueryDocuments({
        patientId,
        disabled: !!documentsState,
    });
    const documentQueryComplete = !isQueryingDocuments;
    useEffect(() => {
        if (documentQueryComplete) {
            patientConsolidatedDispatch({
                type: PatientConsolidatedStateActionType.setPatientsDocuments,
                patientId,
                documents: documentListResult.documents,
            });
        }
    }, [documentQueryComplete, documentListResult]);
    const { consolidated, isConsolidatedLoading } = useGetConsolidated({
        patientId,
        canQueryConsolidated: documentQueryComplete && !consolidatedState,
    });
    useEffect(() => {
        if (consolidated) {
            patientConsolidatedDispatch({
                type: PatientConsolidatedStateActionType.setPatientsConsolidated,
                patientId,
                consolidatedBundle: consolidated,
            });
        }
    }, [consolidated]);
    const [patientTab, setPatientTab] = useState("fhir");
    return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ p: 5 }, { children: [_jsx(PatientsHeader, { patient: patient, isQueryingDocuments: isQueryingDocuments, isFetchingPatient: isFetchingPatient, isDownloadingMr: isMrDownloading, selectedTab: patientTab, lastDocQuery: lastDocQuery, actions: {
                            onQueryPatientDocuments,
                            onEditPatient: sharedState.ehrDetails ? undefined : patientFormDisclosure.onOpen,
                            onGenerateMedicalRecord: patientMRDisclosure.onOpen,
                            onDisplayPatientDemos: patientDemosDisclosure.onOpen,
                            onViewPatientMatches: sharedState.ehrDetails
                                ? undefined
                                : patientMatchesDisclosure.onOpen,
                            onSetPatientTab: setPatientTab,
                        } }), patientTab === "fhir" && (_jsxs(_Fragment, { children: [_jsx(PatientTabs, { filters: filters }), _jsx(PatientFHIRData, { fhirSections: fhirSections, filters: filters, mappedConsolidated: consolidatedState, isConsolidatedLoading: isQueryingDocuments || isConsolidatedLoading, selectedResourceParams: selectedResourceParams })] })), patientTab === "documents" && (_jsx(RawDocuments, { searchDocFilters: searchDocFilters, documents: documentsState, isQueryingDocuments: isQueryingDocuments, actions: {
                            onSearchDocuments: onSearchPatientDocuments,
                        } }))] })), _jsx(DocumentProgressContainer, { isQueryingDocuments: isQueryingDocuments, total: downloadProgress.total, completed: downloadProgress.completed }), _jsx(PatientMatches, { patient: patient, patientMatches: patientMatches, isOpen: patientMatchesDisclosure.isOpen, actions: {
                    onClose: patientMatchesDisclosure.onClose,
                } }), _jsx(PatientForm, { isOpen: patientFormDisclosure.isOpen, selectedPatient: patient, facilities: facilities, isSubmitting: isSubmitting, actions: {
                    onCreateOrUpdatePatient: createOrUpdatePatient,
                    onCloseForm: patientFormDisclosure.onClose,
                } }), _jsx(MrModal, { isOpen: patientMRDisclosure.isOpen, isDownloading: isMrDownloading, existingMrStatus: existingMrStatus, actions: {
                    onCloseModal: patientMRDisclosure.onClose,
                    onDownloadMR,
                } }), _jsx(DemographicsModal, { isOpen: patientDemosDisclosure.isOpen, patient: patient, actions: {
                    onCloseModal: patientDemosDisclosure.onClose,
                } })] }));
}
