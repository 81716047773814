import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, Text, Skeleton } from "@chakra-ui/react";
import PatientMatchesTable from "./patient-matches-table";
export default function PatientMatchesTab({ patient, matches, }) {
    return (_jsxs(Skeleton, Object.assign({ isLoaded: !!matches }, { children: [_jsx(Heading, Object.assign({ size: "md", mb: 3 }, { children: "Current Patient Demo" })), patient && (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", mb: 3 }, { children: [_jsxs(Box, Object.assign({ width: "max-content", borderRadius: "lg", py: 3, px: 6, border: "1px", borderColor: "gray.600", mr: 5 }, { children: [_jsx(PatientDemoValue, { label: "Id", value: patient.id }), _jsx(PatientDemoValue, { label: "Name", value: `${patient.firstName} ${patient.lastName}` }), _jsx(PatientDemoValue, { label: "DOB", value: patient.dob }), _jsx(PatientDemoValue, { label: "Gender", value: patient.genderAtBirth }), _jsx(PatientContactValue, { label: "Email", contactType: "email", value: patient.contact }), _jsx(PatientContactValue, { label: "Phone", contactType: "phone", value: patient.contact })] })), _jsx(PatientAddress, { address: patient.address })] }))), _jsx(PatientMatchesTable, { patientMatches: matches })] })));
}
function PatientContactValue({ label, contactType, value, }) {
    if (!value) {
        return null;
    }
    return (_jsxs(Box, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsxs(Text, Object.assign({ fontWeight: "bold", color: "gray.400", m: 0, mr: 2 }, { children: [label.toUpperCase(), ":", " "] })), _jsx(Box, { children: Array.isArray(value) ? (value.map((v, i) => (_jsx(Box, { children: _jsx(Text, Object.assign({ m: 0 }, { children: v[contactType] })) }, i)))) : (_jsx(Text, Object.assign({ m: 0 }, { children: value[contactType] }))) })] })));
}
function PatientAddress({ address }) {
    if (Array.isArray(address)) {
        return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "row" }, { children: address.map((a, i) => {
                return _jsx(PatientAddress, { address: a }, i);
            }) })));
    }
    return (_jsxs(Box, Object.assign({ mr: 4, borderRadius: "lg", py: 3, px: 6, border: "1px", borderColor: "gray.600" }, { children: [_jsx(PatientDemoValue, { label: "Address line 1", value: address.addressLine1 }), _jsx(PatientDemoValue, { label: "Address line 2", value: address.addressLine2 }), _jsx(PatientDemoValue, { label: "City", value: address.city }), _jsx(PatientDemoValue, { label: "State", value: address.state }), _jsx(PatientDemoValue, { label: "Zip", value: address.zip }), _jsx(PatientDemoValue, { label: "Country", value: address.country })] })));
}
function PatientDemoValue({ label, value }) {
    if (!value) {
        return null;
    }
    return (_jsxs(Box, Object.assign({ mb: 1, display: "flex", alignItems: "center" }, { children: [_jsxs(Text, Object.assign({ fontWeight: "bold", color: "gray.400", m: 0, mr: 2 }, { children: [label.toUpperCase(), ":", " "] })), _jsx(Text, Object.assign({ m: 0 }, { children: value }))] })));
}
