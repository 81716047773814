export const envTypeEnvVarName = "REACT_APP_ENV_TYPE";
export const envTypeDev = "development";
export const envTypeSandbox = "sandbox";
export const envTypeStaging = "staging";
export const envTypeProd = "production";
export function getEnvType() {
    return getEnvVarOrFail(envTypeEnvVarName);
}
export function isDevEnv() {
    return getEnvType() === envTypeDev;
}
export function getEnvVar(varName) {
    return process.env[varName];
}
export function isProdEnv() {
    return getEnvType() === envTypeProd;
}
export function isStagingEnv() {
    return getEnvType() === envTypeStaging;
}
export function getEnvVarOrFail(varName) {
    const value = getEnvVar(varName);
    if (!value || value.trim().length < 1) {
        throw new Error(`Missing ${varName} env var`);
    }
    return value;
}
export function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0)
        return "n/a";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0)
        return `${bytes} ${sizes[i]})`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}
export function isNoAccessError(status) {
    return status === 403;
}
const websiteValidationRegex = /^(?:http:\/\/|https:\/\/)?[\w\d]+(?:[-.]{1}[\w\d]+)*\.[\w]{2,20}(?::[\d]{1,20})?(?:\/\S*)?$/;
export function validateWebsite(website) {
    const isWebsiteValid = websiteValidationRegex.test(website);
    return isWebsiteValid;
}
export function limitStringLength(value, max = 255, suffix = "...") {
    if (!value)
        return value;
    return (value.length > max && value.length > suffix.length
        ? value.substring(0, max - suffix.length) + suffix
        : value);
}
