import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "../../button";
export function DocumentMenu({ contentType, docId, fileName, onDownloadDocument, isDownloading, }) {
    var _a, _b, _c, _d, _e;
    if (!docId || !fileName)
        return null;
    const isDocDownloading = (_b = (_a = isDownloading[docId]) === null || _a === void 0 ? void 0 : _a.downloading) !== null && _b !== void 0 ? _b : false;
    return (_jsx(_Fragment, { children: isXml(contentType) ? (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ mr: 3, leftIcon: _jsx(DownloadIcon, {}), onClick: () => onDownloadDocument(docId, fileName, "pdf"), isLoading: isDocDownloading && ((_c = isDownloading[docId]) === null || _c === void 0 ? void 0 : _c.type) === "pdf" }, { children: "PDF" })), _jsx(Button, Object.assign({ mr: 3, leftIcon: _jsx(DownloadIcon, {}), onClick: () => onDownloadDocument(docId, fileName, "html"), isLoading: isDocDownloading && ((_d = isDownloading[docId]) === null || _d === void 0 ? void 0 : _d.type) === "html" }, { children: "HTML" })), _jsx(Button, Object.assign({ leftIcon: _jsx(DownloadIcon, {}), onClick: () => onDownloadDocument(docId, fileName), isLoading: isDocDownloading && ((_e = isDownloading[docId]) === null || _e === void 0 ? void 0 : _e.type) === "xml" }, { children: "XML" }))] })) : (_jsx(Button, Object.assign({ leftIcon: _jsx(DownloadIcon, {}), onClick: () => onDownloadDocument(docId, fileName), isLoading: isDocDownloading }, { children: "Download" }))) }));
}
export function isXml(mimeType) {
    return mimeType ? mimeType.includes("xml") : false;
}
