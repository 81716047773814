var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { SidePanelContent } from "../../side-panel-content";
import { formatDate, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { SidePanelSection } from "../shared/sidepanel-section";
import { groupConditions } from "./table-data";
export function ConditionContent(_a) {
    var _b, _c, _d, _e;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const conditions = getResourcesFromBundle(mappedConsolidated, "Condition");
    const groupedConditions = groupConditions(conditions);
    const selectedCondition = groupedConditions.find(conditions => conditions.mostRecentCondition.id === rowContents.id);
    if (!selectedCondition) {
        return null;
    }
    const mostRecentCondition = selectedCondition.mostRecentCondition;
    const valueString = (_d = (_c = (_b = mostRecentCondition === null || mostRecentCondition === void 0 ? void 0 : mostRecentCondition.extension) === null || _b === void 0 ? void 0 : _b.find(ext => ext.valueString)) === null || _c === void 0 ? void 0 : _c.valueString) !== null && _d !== void 0 ? _d : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Condition", sourceDocument: {
            id: (_e = mostRecentCondition === null || mostRecentCondition === void 0 ? void 0 : mostRecentCondition.id) !== null && _e !== void 0 ? _e : "",
            fileName: valueString,
        } }, { children: _jsx(ConditionDisplay, { condition: selectedCondition, tableRow: rowContents }) })));
}
function ConditionDisplay({ condition, tableRow, }) {
    if (!condition)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: condition.mostRecentCondition.id,
                    condition: tableRow.condition,
                    status: condition.status,
                } }), condition.sortedOccurrences && condition.sortedOccurrences.length > 1 && (_jsx(_Fragment, { children: _jsx(SidePanelSection, Object.assign({ title: "Occurrences" }, { children: condition.sortedOccurrences.map((p, index) => (_jsx(Text, Object.assign({ fontWeight: index === 0 ? "bold" : "normal" }, { children: `• ${formatDate(p.start)} - ${formatDate(p.end)}` })))) })) })), _jsx(CodesDisplay, { code: condition.mostRecentCondition.code })] }));
}
