var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
import { getPatientFacilityMatches } from "../../../api/patient-matches";
export function useGetPatientMatches({ patientId }) {
    const toast = useMetriportToast();
    const [patientMatches, setPatientMatches] = useState(null);
    useEffect(() => {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const matches = yield getPatientFacilityMatches(patientId);
                    setPatientMatches(matches);
                }
                catch (error) {
                    const msg = "Error retrieving patient matches";
                    toast.error({ title: msg });
                    capture.message(msg, {
                        extra: { context: `patient.matches`, error, patientId: patientId },
                        level: "error",
                    });
                }
            });
        }
        fetchData();
    }, []);
    return { patientMatches };
}
