var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { z } from "zod";
import { apiOSS } from "./apiOSS";
const PATIENT_URL = `/medical/v1/patient`;
const medicalRecordStatusSchema = z.object({
    htmlCreatedAt: z.string().optional(),
    pdfCreatedAt: z.string().optional(),
});
const medicalRecordUrlSchema = z.object({
    url: z.string(),
});
export function getMedicalRecordStatus(patientId) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield apiOSS.get(`${PATIENT_URL}/${patientId}/medical-record-status`);
        const mrStatus = medicalRecordStatusSchema.parse(resp.data);
        return mrStatus;
    });
}
export function getMedicalRecordUrl(patientId, conversionType) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield apiOSS.get(`${PATIENT_URL}/${patientId}/medical-record`, {
            params: {
                conversionType,
            },
        });
        return medicalRecordUrlSchema.parse(resp.data).url;
    });
}
// TEMP: This is a temporary fix to get the query start date
export function getDocumentQueryStatus(patientId) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = apiOSS.get(`/medical/v1/document/query`, {
            params: {
                patientId,
            },
        });
        return resp;
    });
}
