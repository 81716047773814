import { toTitleCase } from "@metriport/shared";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { UNKNOWN_DISPLAY, compare, filterByDate, getResourcesFromBundle } from "../shared";
import { getDiagnosisText } from "./report-content";
export const reportTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "report", hide: true },
        { field: "typeOfReport" },
        { field: "reasonForVisit" },
        { field: "location" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    const reports = getEncounterNotesFromBundle(bundle);
    return {
        columnDefs,
        rowData: getReportRowData({ reports, tableFilters }),
    };
};
export function getEncounterNotesFromBundle(bundle) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const encounters = getResourcesFromBundle(bundle, "Encounter");
    const reports = [];
    if (!bundle) {
        return reports;
    }
    for (const encounter of encounters) {
        const participants = [];
        const locations = [];
        const diagnoses = [];
        const diagnosticReports = [];
        const participantIds = (_b = (_a = encounter.participant) === null || _a === void 0 ? void 0 : _a.map(p => { var _a, _b; return (_b = (_a = p.individual) === null || _a === void 0 ? void 0 : _a.reference) === null || _b === void 0 ? void 0 : _b.split("/").pop(); })) !== null && _b !== void 0 ? _b : [];
        const locationIds = (_d = (_c = encounter.location) === null || _c === void 0 ? void 0 : _c.map(l => { var _a, _b; return (_b = (_a = l.location) === null || _a === void 0 ? void 0 : _a.reference) === null || _b === void 0 ? void 0 : _b.split("/").pop(); })) !== null && _d !== void 0 ? _d : [];
        const diagnosisIds = (_f = (_e = encounter.diagnosis) === null || _e === void 0 ? void 0 : _e.map(d => { var _a, _b; return (_b = (_a = d.condition) === null || _a === void 0 ? void 0 : _a.reference) === null || _b === void 0 ? void 0 : _b.split("/").pop(); })) !== null && _f !== void 0 ? _f : [];
        const allDiagnosticReports = getResourcesFromBundle(bundle, "DiagnosticReport");
        for (const participantId of participantIds) {
            if (!participantId) {
                continue;
            }
            const practitioner = (_g = bundle.Practitioner) === null || _g === void 0 ? void 0 : _g[participantId];
            if (practitioner) {
                participants.push(practitioner);
            }
        }
        for (const locationId of locationIds) {
            if (!locationId) {
                continue;
            }
            const location = (_h = bundle.Location) === null || _h === void 0 ? void 0 : _h[locationId];
            if (location) {
                locations.push(location);
            }
        }
        for (const diagnosisId of diagnosisIds) {
            if (!diagnosisId) {
                continue;
            }
            const diagnosis = (_j = bundle.Condition) === null || _j === void 0 ? void 0 : _j[diagnosisId];
            if (diagnosis) {
                diagnoses.push(diagnosis);
            }
        }
        for (const diagnosticReport of allDiagnosticReports) {
            if (((_l = (_k = diagnosticReport.encounter) === null || _k === void 0 ? void 0 : _k.reference) === null || _l === void 0 ? void 0 : _l.split("/").pop()) === encounter.id) {
                diagnosticReports.push(diagnosticReport);
            }
        }
        reports.push({
            encounter,
            participants,
            locations,
            diagnoses,
            diagnosticReports,
        });
    }
    return reports;
}
function getReportRowData({ reports, tableFilters, }) {
    return reports === null || reports === void 0 ? void 0 : reports.filter(report => report.diagnosticReports.length !== 0).map(report => {
        var _a, _b;
        const encounter = report.encounter;
        const reasonForVisitString = report.diagnoses.map(getDiagnosisText).filter(Boolean).join(", ") || "-";
        const typeOfReport = getReportTypeByLoinc(report.diagnosticReports);
        const locations = Array.from(new Set(report.locations.map(location => location.name))).join(", ");
        return {
            id: (_a = encounter.id) !== null && _a !== void 0 ? _a : "",
            report,
            typeOfReport,
            reasonForVisit: toTitleCase(reasonForVisitString),
            location: locations,
            date: dayjs((_b = encounter.period) === null || _b === void 0 ? void 0 : _b.start).format(ISO_DATE),
        };
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => {
        if (typeof a === "string" && typeof b === "string") {
            return compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter);
        }
        return 0;
    });
}
export function getReportTypeByLoinc(diagnosticReports) {
    const type = diagnosticReports.flatMap(report => {
        var _a, _b, _c, _d;
        let reportType;
        let notePresent;
        (_b = (_a = report.code) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b.forEach(coding => {
            var _a;
            const display = (_a = coding.display) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim();
            if (display === "note") {
                notePresent = true;
            }
            if (display !== UNKNOWN_DISPLAY) {
                reportType = display;
            }
        });
        const text = (_d = (_c = report.code) === null || _c === void 0 ? void 0 : _c.text) === null || _d === void 0 ? void 0 : _d.toLowerCase().trim();
        if (!reportType && !notePresent && text !== UNKNOWN_DISPLAY)
            reportType = text;
        return (reportType && toTitleCase(reportType)) || (notePresent ? "Note" : []);
    });
    const typeDisplay = Array.from(new Set(type)).join(", ");
    return (typeDisplay === null || typeDisplay === void 0 ? void 0 : typeDisplay.length) ? typeDisplay : "-";
}
