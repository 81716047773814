var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SidePanelContent } from "../../side-panel-content";
import { getResourcesFromBundle } from "../shared";
import { getLatestAdministered, getMedicationWithRefs, getMedicationDose } from "./table-data";
import { PeriodDisplay } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
export function MedicationContent(_a) {
    var _b, _c, _d, _e, _f;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const selectedMedication = (_b = mappedConsolidated === null || mappedConsolidated === void 0 ? void 0 : mappedConsolidated["Medication"]) === null || _b === void 0 ? void 0 : _b[rowContents.id];
    const valueString = (_e = (_d = (_c = selectedMedication === null || selectedMedication === void 0 ? void 0 : selectedMedication.extension) === null || _c === void 0 ? void 0 : _c.find(ext => ext.valueString)) === null || _d === void 0 ? void 0 : _d.valueString) !== null && _e !== void 0 ? _e : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Medication", sourceDocument: {
            id: (_f = selectedMedication === null || selectedMedication === void 0 ? void 0 : selectedMedication.id) !== null && _f !== void 0 ? _f : "",
            fileName: valueString,
        } }, { children: _jsx(MedicationDisplay, { medication: selectedMedication !== null && selectedMedication !== void 0 ? selectedMedication : rowContents.raw.medication, tableRow: rowContents, mappedConsolidated: mappedConsolidated }) })));
}
function MedicationDisplay({ medication, tableRow, mappedConsolidated, }) {
    var _a, _b;
    const code = medication.code;
    const medicationAdministration = getResourcesFromBundle(mappedConsolidated, "MedicationAdministration");
    const medicationDispense = getResourcesFromBundle(mappedConsolidated, "MedicationDispense");
    const medicationStatement = getResourcesFromBundle(mappedConsolidated, "MedicationStatement");
    const medicationWithRefs = getMedicationWithRefs(medication, medicationAdministration, medicationDispense, medicationStatement);
    const latestAdministered = getLatestAdministered(medicationWithRefs.administration);
    const dosage = getMedicationDose(medicationWithRefs);
    const route = (_b = (_a = latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.dosage) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.text;
    return (_jsxs(_Fragment, { children: [_jsx(DetailsDisplay, { details: {
                    id: medication.id,
                    name: tableRow.medication,
                    status: latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.status,
                    dosage,
                    route,
                } }), _jsx(PeriodDisplay, { period: latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.effectivePeriod, fallbackDate: latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.effectiveDateTime }), _jsx(CodesDisplay, { code: code })] }));
}
