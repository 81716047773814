import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle, getValidCode } from "../shared";
export const socialHistoryTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "observation" },
        { field: "value" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    const observations = getResourcesFromBundle(bundle, "Observation");
    const socialHistories = getSocialHistory(observations);
    return {
        columnDefs,
        rowData: getSocialHistoryRowData({ socialHistories, tableFilters }),
    };
};
function getSocialHistory(observations) {
    var _a;
    const socialHistory = [];
    for (const observation of observations) {
        const isSocialHistory = (_a = observation.category) === null || _a === void 0 ? void 0 : _a.find(ext => { var _a, _b, _c; return ((_c = (_b = (_a = ext.coding) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "social-history"; });
        if (isSocialHistory) {
            socialHistory.push(observation);
        }
    }
    return socialHistory;
}
function getSocialHistoryRowData({ socialHistories, tableFilters, }) {
    return socialHistories === null || socialHistories === void 0 ? void 0 : socialHistories.map(socialHistory => {
        var _a;
        return ({
            id: (_a = socialHistory.id) !== null && _a !== void 0 ? _a : "-",
            observation: getSocialHistoryDisplay(socialHistory),
            value: renderSocialHistoryValue(socialHistory),
            date: getSocialHistoryDate(socialHistory),
        });
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getSocialHistoryDisplay(socialHistory) {
    var _a;
    const codings = getValidCode((_a = socialHistory.code) === null || _a === void 0 ? void 0 : _a.coding);
    const displays = codings.map(coding => coding.display);
    return displays.length ? displays.join(", ") : "-";
}
function renderSocialHistoryValue(socialHistory) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (socialHistory.valueQuantity) {
        const value = (_a = socialHistory.valueQuantity) === null || _a === void 0 ? void 0 : _a.value;
        const unit = (_c = (_b = socialHistory.valueQuantity) === null || _b === void 0 ? void 0 : _b.unit) === null || _c === void 0 ? void 0 : _c.replace(/[{()}]/g, "");
        return `${value} ${unit}`;
    }
    else if (socialHistory.valueCodeableConcept) {
        return ((_g = (_e = (_d = socialHistory.valueCodeableConcept) === null || _d === void 0 ? void 0 : _d.text) !== null && _e !== void 0 ? _e : (_f = getValidCode(socialHistory.valueCodeableConcept.coding)[0]) === null || _f === void 0 ? void 0 : _f.display) !== null && _g !== void 0 ? _g : "-");
    }
    else {
        return "-";
    }
}
function getSocialHistoryDate(socialHistory) {
    return dayjs(socialHistory.effectiveDateTime).format(ISO_DATE);
}
