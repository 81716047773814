import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, useColorModeValue, } from "@chakra-ui/react";
import PatientMatchesTab from "./patient-matches-tab";
export function PatientMatches({ isOpen, patient, patientMatches, actions, }) {
    // TODO 2182: ADD DESC TO MAKE MORE CLEAR WHAT THIS COMPONENT IS DOING FOR CX
    const colorMode = useColorModeValue("gray.100", "gray.900");
    return (_jsxs(Drawer, Object.assign({ onClose: actions.onClose, isOpen: isOpen !== undefined ? isOpen : true, size: "full" }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ bgColor: "#171923", 
                // https://github.com/chakra-ui/chakra-ui/issues/7091
                motionProps: { initial: "none", exit: "none" }, bg: colorMode }, { children: [_jsx(DrawerCloseButton, {}), _jsx(DrawerHeader, {}), _jsxs(DrawerBody, Object.assign({ pt: 4, position: "relative" }, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", pb: 4, borderBottom: "1px", borderColor: "gray.600", mb: "4" }, { children: _jsx(Heading, Object.assign({ size: "lg" }, { children: "Patient Matches" })) })), _jsx(PatientMatchesTab, { patient: patient, matches: patientMatches })] }))] }))] })));
}
