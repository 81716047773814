var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SearchIcon } from "@chakra-ui/icons";
import { Box, Input, InputGroup, InputLeftElement, useColorMode } from "@chakra-ui/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
export function TableGrid(_a) {
    var { sectionTitle, columnDefs, rowData, headerHeight = 60, groupDefaultExpanded = -1, pagination = true, paginationPageSize = 20, rowStyle, suppressCellFocus = true, defaultColDef } = _a, restProps = __rest(_a, ["sectionTitle", "columnDefs", "rowData", "headerHeight", "groupDefaultExpanded", "pagination", "paginationPageSize", "rowStyle", "suppressCellFocus", "defaultColDef"]);
    const { colorMode } = useColorMode();
    const [search, setSearch] = useState("");
    const gridDefaultColDef = {
        flex: 1,
    };
    const hasRowData = rowData && rowData.length > 0;
    const height = hasRowData ? rowData.length * 61 + 60 + 48 : 0;
    return (_jsxs(_Fragment, { children: [_jsxs(InputGroup, Object.assign({ mb: 4 }, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: "none", color: "gray.300", fontSize: "1.2em" }, { children: _jsx(SearchIcon, {}) })), _jsx(Input, { minW: "300px", w: "25%", value: search, placeholder: `Search for ${sectionTitle !== null && sectionTitle !== void 0 ? sectionTitle : "resources"}`, onChange: e => setSearch(e.target.value), _hover: { borderColor: METRIPORT_PRIMARY }, _active: { borderColor: METRIPORT_PRIMARY }, _focus: { borderColor: METRIPORT_PRIMARY } })] })), _jsx(Box, Object.assign({ id: "table-container", style: { width: "100%", height: height, maxHeight: "750px" } }, { children: _jsx(AgGridReact, Object.assign({}, restProps, { className: colorMode === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz", headerHeight: headerHeight, groupDefaultExpanded: groupDefaultExpanded, columnDefs: columnDefs, defaultColDef: defaultColDef !== null && defaultColDef !== void 0 ? defaultColDef : gridDefaultColDef, rowData: rowData, quickFilterText: search, pagination: pagination, paginationPageSize: paginationPageSize, rowStyle: rowStyle ? rowStyle : { cursor: "pointer" }, suppressCellFocus: suppressCellFocus, suppressScrollOnNewData: true })) }))] }));
}
