import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, createContext, useReducer, useContext } from "react";
import { Skeleton } from "@chakra-ui/react";
import { setAuthTokenHeaders } from "../../../../api/auth";
import { setBaseUrl } from "../../../../api/apiOSS";
import { initialState, reducer, SharedStateActionType, } from "./reducer";
export const SharedStateContext = createContext({
    sharedState: initialState,
    sharedDispatch: () => null,
});
export const SharedStateProvider = ({ authToken, baseUrl, ehrDetails, children, }) => {
    const [sharedState, sharedDispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        function setShared() {
            setAuthTokenHeaders(authToken);
            setBaseUrl(baseUrl);
            sharedDispatch({
                type: SharedStateActionType.set,
                authToken,
                baseUrl,
                ehrDetails,
                isLoaded: true,
            });
        }
        setShared();
    }, [authToken, baseUrl]);
    // Prevent that the UI/components are rendered before set the auth token
    if (!sharedState.isLoaded)
        return _jsx(Skeleton, { height: "100vh", width: "100vw" });
    return (_jsx(SharedStateContext.Provider, Object.assign({ value: { sharedState, sharedDispatch } }, { children: children })));
};
export const useSharedContext = () => useContext(SharedStateContext);
