import { useToast } from "@chakra-ui/react";
export default function useMetriportToast() {
    const chakraToast = useToast();
    const duration = 2000;
    const isClosable = true;
    return {
        info: (options) => chakraToast(Object.assign({ status: "info", duration,
            isClosable }, options)),
        success: (options) => chakraToast(Object.assign({ status: "success", duration,
            isClosable }, options)),
        warning: (options = {}) => chakraToast(Object.assign({ status: "warning", duration,
            isClosable }, options)),
        error: (options = {}) => {
            var _a;
            return chakraToast(Object.assign(Object.assign({ status: "error", duration,
                isClosable }, options), { title: (_a = options.title) !== null && _a !== void 0 ? _a : "Something went wrong, try again." }));
        },
    };
}
