var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ISO_DATE } from "@metriport/shared/common/date";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
import { getDocumentQueryStatus } from "../../../api/medical-record";
const POLLING_INTERVAL = dayjs.duration(3, "second").asMilliseconds();
export function useQueryDocuments({ patientId, disabled, }) {
    const metriportApi = useMetriportApi();
    const toast = useMetriportToast();
    const [searchDocFilters, setSearchDocFilters] = useState(undefined);
    const [isQueryingDocuments, setIsQueryingDocuments] = useState(true);
    const [isPolling, setIsPolling] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState({ total: 0, completed: 0 });
    const [lastDocQuery, setLastDocQuery] = useState(undefined);
    const [documentListResult, setDocuments] = useState({
        documents: [],
    });
    useEffect(() => {
        const filters = localStorage.getItem("doc_filters");
        if (filters) {
            const parsedFilters = JSON.parse(filters);
            const patientFilters = parsedFilters === null || parsedFilters === void 0 ? void 0 : parsedFilters[patientId];
            setSearchDocFilters(patientFilters);
        }
        if (!disabled) {
            onQueryPatientDocuments({});
        }
        else {
            setIsQueryingDocuments(false);
        }
    }, []);
    function onQueryPatientDocuments({ override }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setIsQueryingDocuments(true);
                const documentList = yield metriportApi.listDocuments(patientId);
                if (documentList.documents.length === 0 || override) {
                    yield metriportApi.startDocumentQuery(patientId);
                    setIsPolling(true);
                }
                else {
                    const resp = yield getDocumentQueryStatus(patientId);
                    setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
                    setIsQueryingDocuments(false);
                    searchDocumentsByFilters({ documents: documentList });
                }
            }
            catch (error) {
                capture.error(error, {
                    extra: { patient: patientId, context: `patient.file.list` },
                });
                toast.error();
            }
        });
    }
    useInterval(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        const { download, convert } = yield metriportApi.getDocumentQueryStatus(patientId);
        const successful = (_a = download === null || download === void 0 ? void 0 : download.successful) !== null && _a !== void 0 ? _a : 0;
        const errors = (_b = download === null || download === void 0 ? void 0 : download.errors) !== null && _b !== void 0 ? _b : 0;
        const isProcessing = (download === null || download === void 0 ? void 0 : download.status) === "processing";
        setIsPolling(isProcessing);
        setDownloadProgress({
            total: (_c = download === null || download === void 0 ? void 0 : download.total) !== null && _c !== void 0 ? _c : 0,
            completed: successful + errors,
        });
        if ((download === null || download === void 0 ? void 0 : download.status) === "completed") {
            const documentList = yield metriportApi.listDocuments(patientId);
            searchDocumentsByFilters({ documents: documentList });
            setIsQueryingDocuments(false);
        }
        if ((download === null || download === void 0 ? void 0 : download.status) === "completed" && (convert === null || convert === void 0 ? void 0 : convert.status) === "completed") {
            // TEMP: This is a temporary fix to get the query start date
            const resp = yield getDocumentQueryStatus(patientId);
            setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
            setIsPolling(false);
        }
    }), isPolling ? POLLING_INTERVAL : null);
    function searchDocumentsByFilters({ documents }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (searchDocFilters) {
                const { dateFrom, dateTo, content } = searchDocFilters;
                onSearchPatientDocuments({ dateFrom, dateTo, content, setFiltersInStorage: false });
            }
            else {
                setDocuments(documents);
            }
        });
    }
    function onSearchPatientDocuments({ dateFrom, dateTo, content, setFiltersInStorage = true, }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (setFiltersInStorage) {
                const filters = localStorage.getItem("doc_filters");
                const globalFilters = filters ? JSON.parse(filters) : {};
                const newFilters = Object.assign(Object.assign({}, globalFilters), { [patientId]: {
                        dateFrom,
                        dateTo,
                        content,
                    } });
                localStorage.setItem("doc_filters", JSON.stringify(newFilters));
                setSearchDocFilters({
                    dateFrom,
                    dateTo,
                    content,
                });
            }
            setIsQueryingDocuments(true);
            try {
                const dateFromValue = getFilterValue(dateFrom);
                const dateToValue = getFilterValue(dateTo);
                const contentValue = getFilterValue(content);
                const documentList = yield metriportApi.listDocuments(patientId, {
                    dateFrom: dateFromValue,
                    dateTo: dateToValue,
                    content: contentValue,
                });
                setDocuments(documentList);
            }
            catch (error) {
                capture.error(error, {
                    extra: { patient: patientId, context: `patient.documents.list` },
                });
                toast.error();
            }
            finally {
                setIsQueryingDocuments(false);
            }
        });
    }
    return {
        isQueryingDocuments,
        downloadProgress,
        lastDocQuery,
        documentListResult,
        onQueryPatientDocuments,
        onSearchPatientDocuments,
        searchDocFilters,
    };
}
function getFilterValue(filterInput) {
    if (!filterInput)
        return;
    const trimmedValue = filterInput.trim();
    return trimmedValue && trimmedValue.length > 0 ? trimmedValue : undefined;
}
