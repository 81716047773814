import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from "@chakra-ui/icons";
import { Box, Heading, Spinner, useColorModeValue } from "@chakra-ui/react";
import { NoPatientData } from "../no-data";
import { TableGrid } from "../table-grid";
export function FhirSection({ sectionId, sectionName, columnDefs, rowData, filters, actions, ehrActions, }) {
    const defaultColDef = {
        flex: 1,
        onCellClicked: e => {
            actions.onRowCellClicked(e.data);
        },
        cellClassRules: {
            "cell-filter": params => {
                if (!params.value || !(filters === null || filters === void 0 ? void 0 : filters.stringFilter)) {
                    return false;
                }
                const filtersArray = filters.stringFilter.split(",");
                const hasFilter = filtersArray.some(filter => {
                    return params.value.toLowerCase().includes(filter.toLowerCase());
                });
                return hasFilter;
            },
        },
    };
    columnDefs.map(cd => {
        if (ehrActions && cd.field === "ehr") {
            cd.cellRenderer = () => (ehrActions.writing ? _jsx(Spinner, { mt: 2, ml: 2 }) : _jsx(AddIcon, { ml: 2 }));
            cd.headerName = ehrActions.name;
        }
    });
    return (_jsxs(Box, Object.assign({ id: sectionId, w: "100%", mb: 5, borderRadius: 10, bg: useColorModeValue("white", "gray.800"), px: 7, py: 5, border: "solid", borderColor: useColorModeValue("gray.200", "gray.700") }, { children: [_jsx(Heading, Object.assign({ mb: 5 }, { children: sectionName })), rowData.length > 0 ? (_jsx(TableGrid, { sectionTitle: sectionName, defaultColDef: defaultColDef, columnDefs: columnDefs, rowData: rowData })) : (_jsx(NoPatientData, {}))] })));
}
