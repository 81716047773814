var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { getFirstCodeSpecified, RX_NORM_CODE, NDC_CODE, compare, filterByDate } from "../shared";
import { getResourcesFromBundle } from "../shared";
import { isProdEnv } from "../../../../../shared/util";
export const medicationTableData = ({ bundle, tableFilters, ehrActions, }) => {
    const columnDefs = [
        { field: "raw", hide: true },
        { field: "id", hide: true },
        { field: "medication" },
        { field: "dose" },
        { field: "code" },
        { field: "date", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    if (ehrActions && !isProdEnv()) {
        columnDefs.push({
            field: "ehr",
            onCellClicked: (event) => __awaiter(void 0, void 0, void 0, function* () {
                if (event.data) {
                    ehrActions.setWriting(true);
                    yield ehrActions.write({
                        patientId: ehrActions.ehrPatientId,
                        resource: event.data.raw,
                        path: "medication",
                    });
                    ehrActions.setWriting(false);
                }
            }),
        });
    }
    const medications = getMedications(bundle);
    return {
        columnDefs,
        rowData: getMedicationRowData({ medications, tableFilters }),
    };
};
function getMedications(bundle) {
    const medicationsWithRefs = [];
    const medications = getResourcesFromBundle(bundle, "Medication");
    const medicationAdministration = getResourcesFromBundle(bundle, "MedicationAdministration");
    const medicationDispense = getResourcesFromBundle(bundle, "MedicationDispense");
    const medicationStatement = getResourcesFromBundle(bundle, "MedicationStatement");
    for (const medication of medications) {
        const medicationWithRefs = getMedicationWithRefs(medication, medicationAdministration, medicationDispense, medicationStatement);
        medicationsWithRefs.push(medicationWithRefs);
    }
    return medicationsWithRefs;
}
export function getMedicationWithRefs(medication, medicationAdministrations, medicationDispenses, medicationStatements) {
    const medicationWithRef = {
        medication,
        administration: [],
        dispense: [],
        statement: [],
    };
    for (const medicationTypes of [
        ...medicationAdministrations,
        ...medicationDispenses,
        ...medicationStatements,
    ]) {
        const medRefId = getMedicationReferenceId(medicationTypes) || "";
        if (medRefId === medication.id) {
            if (medicationTypes.resourceType === "MedicationAdministration") {
                medicationWithRef.administration.push(medicationTypes);
            }
            else if (medicationTypes.resourceType === "MedicationDispense") {
                medicationWithRef.dispense.push(medicationTypes);
            }
            else if (medicationTypes.resourceType === "MedicationStatement") {
                medicationWithRef.statement.push(medicationTypes);
            }
        }
    }
    return medicationWithRef;
}
export function getMedicationReferenceId(medication) {
    var _a;
    if ((_a = medication.medicationReference) === null || _a === void 0 ? void 0 : _a.reference) {
        return medication.medicationReference.reference.split("/")[1];
    }
    return undefined;
}
function getMedicationRowData({ medications, tableFilters, }) {
    return medications === null || medications === void 0 ? void 0 : medications.map(medicationWithRefs => {
        var _a, _b, _c;
        return ({
            raw: medicationWithRefs,
            id: (_a = medicationWithRefs.medication.id) !== null && _a !== void 0 ? _a : "-",
            medication: (_c = (_b = medicationWithRefs.medication.code) === null || _b === void 0 ? void 0 : _b.text) !== null && _c !== void 0 ? _c : "-",
            dose: getMedicationDose(medicationWithRefs),
            code: getMedicationCode(medicationWithRefs),
            date: getStartDate(medicationWithRefs),
        });
    }).filter(row => filterByDate(row.date, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(Object.assign(Object.assign({}, a), { raw: undefined }), Object.assign(Object.assign({}, b), { raw: undefined }), tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter)).reduce((acc, curr) => {
        const existing = acc.find(row => row.medication === curr.medication && row.dose === curr.dose);
        if (existing) {
            if (dayjs(curr.date).isAfter(dayjs(existing.date))) {
                return acc.map(row => (row.medication === curr.medication ? curr : row));
            }
            return acc;
        }
        return [...acc, curr];
    }, []);
}
export function getMedicationDose(medicationWithRefs) {
    var _a, _b, _c, _d;
    const latestAdministered = getLatestAdministered(medicationWithRefs.administration);
    const adminDosage = latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.dosage;
    const hasValidDosage = ((_a = adminDosage === null || adminDosage === void 0 ? void 0 : adminDosage.dose) === null || _a === void 0 ? void 0 : _a.value) && ((_b = adminDosage === null || adminDosage === void 0 ? void 0 : adminDosage.dose) === null || _b === void 0 ? void 0 : _b.unit);
    const adminInstructions = hasValidDosage
        ? `${(_c = adminDosage === null || adminDosage === void 0 ? void 0 : adminDosage.dose) === null || _c === void 0 ? void 0 : _c.value} ${(_d = adminDosage === null || adminDosage === void 0 ? void 0 : adminDosage.dose) === null || _d === void 0 ? void 0 : _d.unit}`
        : undefined;
    return adminInstructions !== null && adminInstructions !== void 0 ? adminInstructions : "-";
}
function getMedicationCode(medicationWithRefs) {
    var _a, _b, _c;
    const coding = getFirstCodeSpecified((_c = (_b = (_a = medicationWithRefs.medication) === null || _a === void 0 ? void 0 : _a.code) === null || _b === void 0 ? void 0 : _b.coding) !== null && _c !== void 0 ? _c : [], [
        RX_NORM_CODE,
        NDC_CODE,
    ]);
    if (coding) {
        return `${coding.system}: ${coding.code}`;
    }
    return "-";
}
function getStartDate(medicationWithRefs) {
    var _a, _b;
    const latestAdministered = getLatestAdministered(medicationWithRefs.administration);
    const onsetDateTime = latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.effectiveDateTime;
    const onsetPeriodStart = (_a = latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.effectivePeriod) === null || _a === void 0 ? void 0 : _a.start;
    const onsetPeriodEnd = (_b = latestAdministered === null || latestAdministered === void 0 ? void 0 : latestAdministered.effectivePeriod) === null || _b === void 0 ? void 0 : _b.end;
    const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;
    if (time) {
        return dayjs(time).format(ISO_DATE);
    }
    return "-";
}
export function getLatestAdministered(administrations) {
    return administrations.sort((a, b) => {
        var _a, _b, _c, _d;
        const aTime = a.effectiveDateTime || ((_a = a.effectivePeriod) === null || _a === void 0 ? void 0 : _a.start) || ((_b = a.effectivePeriod) === null || _b === void 0 ? void 0 : _b.end);
        const bTime = b.effectiveDateTime || ((_c = b.effectivePeriod) === null || _c === void 0 ? void 0 : _c.start) || ((_d = b.effectivePeriod) === null || _d === void 0 ? void 0 : _d.end);
        return dayjs(bTime).diff(dayjs(aTime));
    })[0];
}
