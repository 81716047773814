import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Text } from "@chakra-ui/react";
import { toTitleCase } from "@metriport/shared";
export function ReportContent({ encounterNote }) {
    return (_jsxs(Box, Object.assign({ w: "100%" }, { children: [_jsx(Participants, { participants: encounterNote.participants }), _jsx(Locations, { locations: encounterNote.locations }), _jsx(Diagnosis, { diagnoses: encounterNote.diagnoses }), _jsx(Divider, { my: 4, color: "gray.500" }), _jsx(ReportNotes, { reports: encounterNote.diagnosticReports })] })));
}
function Participants({ participants }) {
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsx(Text, Object.assign({ w: 100, m: 0, mr: 2, fontSize: 18, fontWeight: "bold" }, { children: "Provider:" })), _jsx(Box, { children: participants.map((participant, i) => {
                        var _a, _b, _c, _d, _e;
                        return (_jsxs(Text, Object.assign({ m: 0, fontSize: 18 }, { children: [(_c = (_b = (_a = participant.name) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.given) === null || _c === void 0 ? void 0 : _c[0], " ", (_e = (_d = participant.name) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.family, i < participants.length - 1 ? ", " : ""] }), i));
                    }) })] })) }));
}
function Locations({ locations }) {
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsx(Text, Object.assign({ w: 100, m: 0, mr: 2, fontSize: 18, fontWeight: "bold" }, { children: "Location:" })), _jsx(Box, { children: locations === null || locations === void 0 ? void 0 : locations.map((location, i) => (_jsxs(Text, Object.assign({ m: 0, fontSize: 18 }, { children: [location.name, i < locations.length - 1 ? ", " : ""] }), i))) })] })) }));
}
function Diagnosis({ diagnoses }) {
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsx(Text, Object.assign({ w: 100, m: 0, mr: 2, fontSize: 18, fontWeight: "bold" }, { children: "Diagnosis:" })), _jsx(Box, { children: _jsx(Text, Object.assign({ m: 0, fontSize: 18 }, { children: (diagnoses === null || diagnoses === void 0 ? void 0 : diagnoses.map(getDiagnosisText).filter(Boolean).join(", ")) || "-" })) })] })) }));
}
export function getDiagnosisText(diagnosis) {
    var _a, _b, _c, _d;
    const displayText = (_b = (_a = diagnosis.code) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b.flatMap(coding => coding.display || []).join(", ");
    return ((_d = (_c = diagnosis.code) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : (displayText && displayText.length ? toTitleCase(displayText) : undefined));
}
function ReportNotes({ reports }) {
    return (_jsx(Box, { children: _jsx(Box, { children: reports === null || reports === void 0 ? void 0 : reports.map((report, i) => (_jsx(ReportNote, { report: report, showDivider: i < reports.length - 1 }, i))) }) }));
}
function ReportNote({ report, showDivider }) {
    const cleanNote = getCleanNoteFromReport(report);
    if (!cleanNote) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: _jsx(Box, Object.assign({ mb: 5, whiteSpace: "pre-line" }, { children: cleanNote })) })), showDivider && _jsx(Divider, { mt: 4, color: "gray.500", borderWidth: "2px" })] }));
}
export function getCleanNoteFromReport(report) {
    var _a, _b;
    const note = ((_b = (_a = report.presentedForm) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.data) || "";
    const noJunkNote = removeEncodedStrings(note);
    const decodeNote = atob(noJunkNote);
    return cleanUpNote(decodeNote);
}
const REMOVE_FROM_NOTE = [
    "xLabel",
    "5/5",
    "Â°F",
    "â¢",
    "documented in this encounter",
    "xnoIndent",
    "Formatting of this note might be different from the original.",
    "<content>",
    "</content>",
    "<root>",
    "</root>",
];
function cleanUpNote(note) {
    return note
        .trim()
        .replace(new RegExp(REMOVE_FROM_NOTE.join("|"), "g"), "")
        .replace(/<ID>.*?<\/ID>/g, "")
        .replace(/<styleCode>.*?<\/styleCode>/g, "");
}
function removeEncodedStrings(valueString) {
    return valueString.replace(/&#x3D;/g, "").trim();
}
