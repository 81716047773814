import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Heading, Skeleton, Tag, useDisclosure } from "@chakra-ui/react";
import { Button } from "./button";
import { buildDocumentTableData } from "./fhir-sections/documents/table-data";
import { NoPatientData } from "./no-data";
import { SearchModal } from "./search-modal";
import { TableGrid } from "./table-grid";
export function RawDocuments({ searchDocFilters, documents, isQueryingDocuments, actions, }) {
    const searchDocumentsDisclosure = useDisclosure();
    const mappedDocumentReferences = {};
    documents === null || documents === void 0 ? void 0 : documents.forEach(doc => {
        if (doc.id) {
            mappedDocumentReferences[doc.id] = doc;
        }
    });
    const { columnDefs, rowData } = buildDocumentTableData({
        bundle: {
            ["DocumentReference"]: mappedDocumentReferences,
        },
        tableFilters: undefined,
        noActions: false,
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ pt: 5 }, { children: [_jsxs(Flex, Object.assign({ mb: 4, flexDirection: "row", justifyContent: "space-between" }, { children: [_jsx(Heading, { children: "Raw Documents" }), _jsxs(Flex, Object.assign({ flexDirection: { base: "column-reverse", xl: "row" } }, { children: [searchDocFilters && _jsx(SelectedFilters, { filters: searchDocFilters }), _jsx(Button, Object.assign({ mb: { base: "2", xl: 0 }, onClick: searchDocumentsDisclosure.onOpen }, { children: "Search Documents" }))] }))] })), (documents === null || documents === void 0 ? void 0 : documents.length) === 0 ? (_jsx(Skeleton, Object.assign({ height: "full", isLoaded: !isQueryingDocuments }, { children: _jsx(NoPatientData, {}) }))) : (_jsx(Box, Object.assign({ position: "relative" }, { children: _jsx(Skeleton, Object.assign({ height: "full", isLoaded: !isQueryingDocuments }, { children: _jsx(TableGrid, { sectionTitle: "Documents", columnDefs: columnDefs, rowData: rowData }) })) })))] })), _jsx(SearchModal, { isOpen: searchDocumentsDisclosure.isOpen, onClose: searchDocumentsDisclosure.onClose, onSearchDocuments: actions.onSearchDocuments, isFetchingDocuments: isQueryingDocuments, searchDocFilters: searchDocFilters })] }));
}
function SelectedFilters({ filters }) {
    return (_jsxs(_Fragment, { children: [filters.dateFrom && _jsx(FilterTag, { filter: `Date From: ${filters.dateFrom}` }), filters.dateTo && _jsx(FilterTag, { filter: `Date To: ${filters.dateTo}` }), filters.content && _jsx(FilterTag, { filter: `Content: ${filters.content.substring(0, 10)}...` })] }));
}
function FilterTag({ filter }) {
    return (_jsx(Tag, Object.assign({ variant: "subtle", colorScheme: "purple", size: "lg", mr: { base: 0, xl: 2 }, mb: { base: 2, xl: 0 } }, { children: filter })));
}
