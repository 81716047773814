import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";
export const allergyTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "allergy" },
        { field: "manifestation" },
        { field: "firstSeen", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        { field: "status" },
    ];
    const allergies = getResourcesFromBundle(bundle, "AllergyIntolerance");
    return {
        columnDefs,
        rowData: getAllergyRowData({ allergies, tableFilters }),
    };
};
function getAllergyRowData({ allergies, tableFilters, }) {
    return allergies === null || allergies === void 0 ? void 0 : allergies.map(allergy => {
        var _a;
        return ({
            id: (_a = allergy.id) !== null && _a !== void 0 ? _a : "-",
            allergy: getAllergyName(allergy),
            manifestation: getAllergyManifestation(allergy),
            firstSeen: getAllergyOnset(allergy),
            status: getAllergyClinicalStatus(allergy),
        });
    }).filter(row => filterByDate(row.firstSeen, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getAllergyName(allergy) {
    var _a, _b;
    const names = [];
    for (const reaction of (_a = allergy.reaction) !== null && _a !== void 0 ? _a : []) {
        if ((_b = reaction.substance) === null || _b === void 0 ? void 0 : _b.text) {
            names.push(reaction.substance.text);
        }
    }
    if (names && names.length > 0) {
        return names.join(", ");
    }
    return "-";
}
function getAllergyManifestation(allergy) {
    var _a, _b;
    const manifestations = [];
    for (const reaction of (_a = allergy.reaction) !== null && _a !== void 0 ? _a : []) {
        for (const manifestation of (_b = reaction.manifestation) !== null && _b !== void 0 ? _b : []) {
            if (manifestation.text) {
                manifestations.push(manifestation.text);
            }
        }
    }
    if (manifestations && manifestations.length > 0) {
        return manifestations.join(", ");
    }
    return "-";
}
function getAllergyOnset(allergy) {
    if (allergy.onsetDateTime) {
        return dayjs(allergy.onsetDateTime).format(ISO_DATE);
    }
    else if (allergy.recordedDate) {
        return dayjs(allergy.recordedDate).format(ISO_DATE);
    }
    return "-";
}
function getAllergyClinicalStatus(allergy) {
    var _a, _b, _c, _d;
    return (_d = (_c = (_b = (_a = allergy.clinicalStatus) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code) !== null && _d !== void 0 ? _d : "-";
}
