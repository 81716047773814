var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SidePanelContent } from "../../side-panel-content";
import { ReportContent as Content } from "./report-content";
export function ReportContent(_a) {
    var _b, _c, _d, _e, _f, _g;
    var { mappedConsolidated } = _a, rowContents = __rest(_a, ["mappedConsolidated"]);
    const selectedReport = (_b = mappedConsolidated === null || mappedConsolidated === void 0 ? void 0 : mappedConsolidated["Encounter"]) === null || _b === void 0 ? void 0 : _b[(_c = rowContents.report.encounter.id) !== null && _c !== void 0 ? _c : ""];
    const valueString = (_f = (_e = (_d = selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.extension) === null || _d === void 0 ? void 0 : _d.find(ext => ext.valueString)) === null || _e === void 0 ? void 0 : _e.valueString) !== null && _f !== void 0 ? _f : "";
    return (_jsx(SidePanelContent, Object.assign({ title: "Report", sourceDocument: {
            id: (_g = selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.id) !== null && _g !== void 0 ? _g : "",
            fileName: valueString,
        } }, { children: _jsx(Content, { encounterNote: rowContents.report }) })));
}
