import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs, TabList, Tab, useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem, useDisclosure, useBreakpointValue, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { fhirSections } from "./fhir-sections/sections";
const MAX_TABS_SHOWN = 8;
export function PatientTabs({ filters, headerOffset = 75, }) {
    var _a;
    const [index, setIndex] = useState(0);
    const [isManualScroll, setIsManualScroll] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const tabOptions = generateTabOptions(filters);
    const maxTabsShown = (_a = useBreakpointValue({
        base: 0,
        lg: 4,
        "2xl": MAX_TABS_SHOWN,
    })) !== null && _a !== void 0 ? _a : 0;
    useEffect(() => {
        const onScroll = () => {
            if (isManualScroll) {
                return;
            }
            const index = setIndexIfInFrame(window.scrollY, tabOptions, headerOffset);
            if (index === undefined) {
                return;
            }
            if (index >= maxTabsShown) {
                setIndex(maxTabsShown);
                return;
            }
            setIndex(index);
        };
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll, { passive: true });
        window.addEventListener("wheel", () => setIsManualScroll(false), { passive: true });
        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("wheel", () => setIsManualScroll(false));
        };
    }, [isManualScroll]);
    return (_jsx(Tabs, Object.assign({ bg: useColorModeValue("gray.100", "gray.900"), onChange: index => {
            if (index < maxTabsShown) {
                setIsManualScroll(true);
                scrollToIndex(index, tabOptions, headerOffset);
                setIndex(index);
            }
        }, py: 3, top: 0, position: "sticky", variant: "soft-rounded", zIndex: 1, index: index }, { children: _jsxs(TabList, Object.assign({ overflowX: "scroll", justifyContent: maxTabsShown > 0 ? "left" : "right" }, { children: [tabOptions
                    .filter(tab => tab.index < maxTabsShown)
                    .map((tab, i) => {
                    return (_jsx(Tab, Object.assign({ color: useColorModeValue("black", "white"), _selected: { color: "white", bg: METRIPORT_PRIMARY }, whiteSpace: "nowrap", cursor: "pointer", py: 4, px: 6, mr: 4, ml: i === 0 ? 0 : 4 }, { children: tab.display }), tab.index));
                }), _jsx(Tab, Object.assign({ _selected: { bg: METRIPORT_PRIMARY } }, { children: _jsxs(Menu, Object.assign({ placement: "bottom", isOpen: isOpen }, { children: [_jsx(MenuButton, Object.assign({ fontWeight: "normal", bg: "transparent", color: index >= maxTabsShown ? "white" : undefined, _active: { bg: "transparent" }, _hover: { bg: "transparent" }, onMouseEnter: onOpen, onMouseLeave: onClose, as: Button }, { children: maxTabsShown > 0 ? "Other" : "Sections" })), _jsx(MenuList, Object.assign({ onMouseEnter: onOpen, onMouseLeave: onClose }, { children: tabOptions
                                    .filter(tab => tab.index >= maxTabsShown)
                                    .map((tab, i) => {
                                    return (_jsx(MenuItem, Object.assign({ color: useColorModeValue("black", "white"), onClick: () => {
                                            setIsManualScroll(true);
                                            scrollToIndex(tab.index, tabOptions, headerOffset);
                                            setIndex(maxTabsShown);
                                            onClose();
                                        } }, { children: tab.display }), i));
                                }) }))] })) }))] })) })));
}
function generateTabOptions(mrFilterSettings) {
    if (!mrFilterSettings) {
        return fhirSections.map((section, i) => {
            return {
                index: i,
                id: section.key,
                display: section.title,
            };
        });
    }
    return mrFilterSettings.map((filter, i) => {
        var _a;
        const display = (_a = fhirSections.find(section => section.key === filter.key)) === null || _a === void 0 ? void 0 : _a.title;
        return {
            index: i,
            id: filter.key,
            display: display !== null && display !== void 0 ? display : "",
        };
    });
}
function setIndexIfInFrame(scrollOffset, tabOptions, headerOffset) {
    const currentOffset = scrollOffset + headerOffset;
    for (let i = 0; i < tabOptions.length; i++) {
        const tab = tabOptions[i];
        if (!tab)
            continue;
        const element = document.getElementById(tab.id);
        if (!element) {
            continue;
        }
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        if (currentOffset >= offsetPosition && currentOffset < offsetPosition + element.clientHeight) {
            return i;
        }
    }
    return undefined;
}
function scrollToIndex(index, tabOptions, headerOffset) {
    const tab = tabOptions[index];
    if (!tab)
        return;
    const element = document.getElementById(tab.id);
    if (!element) {
        return;
    }
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
    });
}
