import { compare, getFirstCodeSpecified, getResourcesFromBundle, getValidCode } from "../shared";
export const familyMemberHistoryTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "familyMember" },
        { field: "sex" },
        { field: "conditions" },
        { field: "deceased" },
    ];
    const familyMemberHistories = getResourcesFromBundle(bundle, "FamilyMemberHistory");
    return {
        columnDefs,
        rowData: getFamilyMemberHistoryRowData({ familyMemberHistories, tableFilters }),
    };
};
function getFamilyMemberHistoryRowData({ familyMemberHistories, tableFilters, }) {
    return familyMemberHistories === null || familyMemberHistories === void 0 ? void 0 : familyMemberHistories.map(familyMemberHistory => {
        var _a;
        return ({
            id: (_a = familyMemberHistory.id) !== null && _a !== void 0 ? _a : "-",
            familyMember: getFamilyMemberDisplay(familyMemberHistory),
            sex: renderAdministrativeGender(familyMemberHistory),
            conditions: renderFamilyHistoryFamilyMemberHistorys(familyMemberHistory),
            deceased: renderFamilyMemberDecased(familyMemberHistory),
        });
    }).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getFamilyMemberDisplay(familyMemberHistory) {
    var _a, _b;
    const codings = getValidCode((_a = familyMemberHistory.relationship) === null || _a === void 0 ? void 0 : _a.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
        return displays.join(", ");
    }
    else if ((_b = familyMemberHistory.relationship) === null || _b === void 0 ? void 0 : _b.text) {
        return familyMemberHistory.relationship.text;
    }
    return "-";
}
function renderAdministrativeGender(familyMemberHistory) {
    var _a;
    const adminGenCode = getFirstCodeSpecified((_a = familyMemberHistory.sex) === null || _a === void 0 ? void 0 : _a.coding, [
        "administrativegender",
    ]);
    if (adminGenCode === null || adminGenCode === void 0 ? void 0 : adminGenCode.code) {
        return adminGenCode.code;
    }
    return "-";
}
function renderFamilyHistoryFamilyMemberHistorys(familyMemberHistory) {
    var _a, _b;
    const conditions = (_a = familyMemberHistory.condition) === null || _a === void 0 ? void 0 : _a.map(condition => {
        var _a, _b, _c, _d;
        return (_b = (_a = condition.code) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : (_d = getValidCode((_c = condition.code) === null || _c === void 0 ? void 0 : _c.coding)[0]) === null || _d === void 0 ? void 0 : _d.display;
    });
    return (_b = conditions === null || conditions === void 0 ? void 0 : conditions.join(", ")) !== null && _b !== void 0 ? _b : "-";
}
function renderFamilyMemberDecased(familyMemberHistory) {
    var _a;
    const deceasedFamilyMember = (_a = familyMemberHistory.condition) === null || _a === void 0 ? void 0 : _a.find(condition => {
        return condition.contributedToDeath === true;
    });
    if (deceasedFamilyMember) {
        return "Yes";
    }
    return "No";
}
