import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";
export const encounterTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "class" },
        { field: "location" },
        { field: "startDate", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        { field: "endDate" },
    ];
    const encounters = getResourcesFromBundle(bundle, "Encounter");
    const locations = getResourcesFromBundle(bundle, "Location");
    return {
        columnDefs,
        rowData: getEncounterRowData({ encounters, locations, tableFilters }),
    };
};
function getEncounterRowData({ encounters, locations, tableFilters, }) {
    return encounters === null || encounters === void 0 ? void 0 : encounters.map(encounter => {
        var _a, _b, _c;
        return ({
            id: (_a = encounter.id) !== null && _a !== void 0 ? _a : "-",
            // encounter: getEncountersDisplay(encounter),
            location: getEncounterLocation(encounter, locations),
            class: renderClassDisplay(encounter),
            startDate: ((_b = encounter.period) === null || _b === void 0 ? void 0 : _b.start) ? dayjs(encounter.period.start).format(ISO_DATE) : "-",
            endDate: ((_c = encounter.period) === null || _c === void 0 ? void 0 : _c.end) ? dayjs(encounter.period.end).format(ISO_DATE) : "-",
        });
    }).filter(row => filterByDate(row.startDate, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
// TODO: Re-introduce when #2357 is resolved
// function getEncountersDisplay(encounter: Encounter): string {
//   const reasons: string[] = [];
//   for (const reason of encounter.reasonCode ?? []) {
//     const text = reason.text;
//     if (text) {
//       reasons.push(text);
//     }
//   }
//   if (reasons.length) {
//     return reasons.join(", ");
//   } else {
//     const codings = getValidCode(encounter.reasonCode?.[0]?.coding);
//     if (codings.length && codings[0]?.display) {
//       return codings[0].display;
//     }
//   }
//   return "-";
// }
function getEncounterLocation(encounter, locations) {
    var _a, _b, _c, _d, _e, _f;
    const locationId = (_e = (_d = (_c = (_b = (_a = encounter.location) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.reference) === null || _d === void 0 ? void 0 : _d.split("/")) === null || _e === void 0 ? void 0 : _e[1];
    const location = locations.find(l => l.id === locationId);
    return (_f = location === null || location === void 0 ? void 0 : location.name) !== null && _f !== void 0 ? _f : "-";
}
function renderClassDisplay(encounter) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const isDisplayIsNotValid = ((_a = encounter.class) === null || _a === void 0 ? void 0 : _a.display) === undefined || ((_b = encounter.class) === null || _b === void 0 ? void 0 : _b.display) === "unknown";
    if (((_c = encounter.class) === null || _c === void 0 ? void 0 : _c.display) && !isDisplayIsNotValid) {
        return (_d = encounter.class) === null || _d === void 0 ? void 0 : _d.display;
    }
    else if (((_e = encounter.class) === null || _e === void 0 ? void 0 : _e.code) && !isDisplayIsNotValid) {
        const extension = (_g = (_f = encounter.class) === null || _f === void 0 ? void 0 : _f.extension) === null || _g === void 0 ? void 0 : _g.find(coding => {
            var _a, _b;
            return ((_a = coding.valueCoding) === null || _a === void 0 ? void 0 : _a.code) === ((_b = encounter.class) === null || _b === void 0 ? void 0 : _b.code);
        });
        return (_j = (_h = extension === null || extension === void 0 ? void 0 : extension.valueCoding) === null || _h === void 0 ? void 0 : _h.display) !== null && _j !== void 0 ? _j : "-";
    }
    else {
        return "-";
    }
}
