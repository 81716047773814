import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";
export const procedureTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "procedure" },
        { field: "datePerformed", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        { field: "status" },
    ];
    const procedures = getResourcesFromBundle(bundle, "Procedure");
    return {
        columnDefs,
        rowData: getProcedureRowData({ procedures, tableFilters }),
    };
};
function getProcedureRowData({ procedures, tableFilters, }) {
    return procedures === null || procedures === void 0 ? void 0 : procedures.map(procedure => {
        var _a, _b;
        return ({
            id: (_a = procedure.id) !== null && _a !== void 0 ? _a : "-",
            procedure: getProcedureName(procedure),
            datePerformed: getProcedureDatePerformed(procedure),
            status: (_b = procedure.status) !== null && _b !== void 0 ? _b : "-",
        });
    }).filter(row => filterByDate(row.datePerformed, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getProcedureName(procedure) {
    var _a, _b, _c, _d;
    const displayText = (_b = (_a = procedure.code) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b.flatMap(coding => coding.display || []).join(", ");
    return (_d = (_c = procedure.code) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : (displayText && displayText.length ? displayText : "-");
}
function getProcedureDatePerformed(procedure) {
    var _a, _b;
    const validDate = (_a = procedure.performedDateTime) !== null && _a !== void 0 ? _a : (_b = procedure.performedPeriod) === null || _b === void 0 ? void 0 : _b.start;
    if (!validDate) {
        return "-";
    }
    return dayjs(validDate).format(ISO_DATE);
}
