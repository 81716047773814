import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, Flex, Progress, Text, useColorModeValue } from "@chakra-ui/react";
export const DocumentProgressContainer = ({ isQueryingDocuments, total, completed, }) => {
    const progressValue = total ? (completed / total) * 100 : 0;
    const bgColor = useColorModeValue("white", "gray.900");
    if (isQueryingDocuments && total > 0) {
        return (_jsx(Box, Object.assign({ w: "100%", position: "fixed", left: 0, right: 0, bottom: "100px", display: "flex", justifyContent: "center" }, { children: _jsxs(Card, Object.assign({ bg: bgColor, border: "solid #d3d3d3 1px", p: 5, w: 500, mx: 5 }, { children: [_jsxs(Flex, Object.assign({ mb: 2, justifyContent: "space-between" }, { children: [_jsx(Text, { children: "Querying documents" }), _jsxs(Text, { children: [completed, " / ", total] })] })), _jsx(Progress, { colorScheme: "purple", hasStripe: true, value: progressValue })] })) })));
    }
    return null;
};
