var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { z } from "zod";
import { apiOSS } from "./apiOSS";
const PATIENT_URL = `/medical/v1/patient`;
const consolidatedWebhookSchema = z.object({
    requestId: z.string(),
    fileUrl: z.string().optional(),
    status: z.string().optional(),
    conversionType: z.string().optional(),
    message: z.string().optional(),
});
export function getConsolidatedWebhook(patientId, requestId) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield apiOSS.get(`${PATIENT_URL}/${patientId}/consolidated/webhook`, {
            params: { requestId },
        });
        const webhook = consolidatedWebhookSchema.parse(resp.data);
        return webhook;
    });
}
