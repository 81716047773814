var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
import { getMedicalRecordStatus, getMedicalRecordUrl } from "../../../api/medical-record";
dayjs.extend(duration);
const POLLING_INTERVAL = dayjs.duration({ seconds: 3 });
export const mrFormats = ["html", "pdf"];
export function useGetMedicalRecord({ patientId }) {
    const metriportApi = useMetriportApi();
    const toast = useMetriportToast();
    const [existingMrStatus, setExistingMrStatus] = useState(undefined);
    const [isMrDownloading, setIsMrDownloading] = useState({});
    const [shouldPoll, setShouldPoll] = useState(false);
    useEffect(() => {
        fetchMedicalRecordStatus();
    }, []);
    function onDownloadMR(downloadExistingMr, conversionType, dateTo, dateFrom) {
        return __awaiter(this, void 0, void 0, function* () {
            setIsMrDownloading(Object.assign(Object.assign({}, isMrDownloading), { [conversionType]: {
                    downloading: true,
                } }));
            if (downloadExistingMr) {
                setShouldPoll(true);
                toast.info({
                    title: "Hold tight... This might take a while.",
                    duration: 5000,
                });
                const dateToUndefined = dateTo !== "" ? dateTo : undefined;
                const dateFromUndefined = dateFrom !== "" ? dateFrom : undefined;
                metriportApi.startConsolidatedQuery(patientId, undefined, dateFromUndefined, dateToUndefined, conversionType);
            }
            else {
                yield getMedicalRecord(conversionType);
            }
        });
    }
    function checkPopupEnabled() {
        const testWindow = window.open("", "_blank", "width=100,height=100");
        if (testWindow) {
            testWindow.close();
        }
        else {
            toast.warning({
                title: "Make sure the pop ups are enabled for this site to view the Medical Record Summary.",
                duration: 5000,
            });
        }
    }
    useInterval(() => __awaiter(this, void 0, void 0, function* () {
        const { queries } = yield metriportApi.getConsolidatedQueryStatus(patientId);
        if (queries) {
            const allDone = queries.every(query => query.status !== "processing");
            if (allDone) {
                for (const query of queries) {
                    if (query.conversionType === "html" || query.conversionType === "pdf") {
                        yield processMrCreation(query.conversionType, query.status);
                    }
                }
                setShouldPoll(false);
                const isDownloadingUpdated = queries.reduce((acc, query) => {
                    if (query.conversionType === "html" || query.conversionType === "pdf") {
                        return Object.assign(Object.assign({}, acc), { [query.conversionType]: {
                                downloading: false,
                            } });
                    }
                    return acc;
                }, {});
                setIsMrDownloading(Object.assign(Object.assign({}, isMrDownloading), isDownloadingUpdated));
            }
        }
    }), shouldPoll ? POLLING_INTERVAL.asMilliseconds() : null);
    function processMrCreation(conversionType, queryStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            const mrStatus = yield fetchMedicalRecordStatus();
            if (queryStatus === "completed" && doesMrExist(mrStatus)) {
                yield getMedicalRecord(conversionType);
            }
            if (queryStatus === "failed" || !doesMrExist(mrStatus)) {
                toast.warning({
                    title: "No content to generate a Medical Record Summary.",
                    duration: 5000,
                });
            }
        });
    }
    function fetchMedicalRecordStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const mrStatus = yield getMedicalRecordStatus(patientId);
                if (mrStatus) {
                    setExistingMrStatus(mrStatus);
                }
                return mrStatus;
            }
            catch (error) {
                capture.error(error, { extra: { context: "document.fetchMRInfo" } });
                return;
            }
        });
    }
    function getMedicalRecord(conversionType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = yield getMedicalRecordUrl(patientId, conversionType);
                if (!url) {
                    toast.info({
                        title: "No medical resources were found for this patient.",
                        duration: 5000,
                    });
                    return;
                }
                checkPopupEnabled();
                // TODO move this to a helper function
                const a = document.createElement("a");
                a.href = url;
                a.download = patientId + "_MR";
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            catch (err) {
                capture.error(err, { extra: { patientId, context: `document.existingMR.download` } });
                toast.error();
            }
            finally {
                setIsMrDownloading(Object.assign(Object.assign({}, isMrDownloading), { [conversionType]: {
                        downloading: false,
                    } }));
            }
        });
    }
    return { onDownloadMR, isMrDownloading, existingMrStatus };
}
function doesMrExist(mrStatus) {
    var _a;
    return (_a = (mrStatus && Object.keys(mrStatus).length > 0)) !== null && _a !== void 0 ? _a : false;
}
