import { capture } from "../../shared/capture";
import Constants from "./constants";
export function isSandbox(userId) {
    const isSandbox = readLocalStorageValue(Constants.isSandboxKey, {
        [userId]: false,
    });
    return Boolean(isSandbox[userId]);
}
// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON(value) {
    try {
        return value === "undefined" ? undefined : JSON.parse(value !== null && value !== void 0 ? value : "");
    }
    catch (_a) {
        return undefined;
    }
}
const defaultFormatterParams = {
    currency: "USD",
};
export const formatter = new Intl.NumberFormat("en-US", defaultFormatterParams);
export const formatterNoDecimal = new Intl.NumberFormat("en-US", Object.assign(Object.assign({}, defaultFormatterParams), { minimumFractionDigits: 0, style: "currency" }));
export function readLocalStorageValue(key, initialValue) {
    // Get from local storage then parse stored json or return initialValue
    if (typeof window === "undefined") {
        return initialValue;
    }
    try {
        const item = window.localStorage.getItem(key);
        return item ? parseJSON(item) : initialValue;
    }
    catch (error) {
        capture.error(error, { extra: { context: `localStorage.value.read` } });
        return initialValue;
    }
}
export const sleep = (timeInMs) => new Promise(resolve => setTimeout(resolve, timeInMs));
export const emptyStringToUndefined = (v) => {
    const toStr = String(v);
    if (!toStr || toStr.trim().length <= 0)
        return undefined;
    return toStr;
};
export function mapToOptions(map) {
    return Object.keys(map).flatMap(k => {
        const label = map[k];
        if (!label)
            return [];
        return [{ label, value: k }];
    });
}
