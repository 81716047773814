import { PatientOverview, SharedStateProvider } from "@metriport/shared-internal";
import { useMemo } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getOSSBaseURL } from "../../../api/apiOSS";
import { isApiKeyGenerated } from "../../../domain/api-key";
import { useAppContext } from "../../contexts/app";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";
import NoApiKey from "../no-api-key";

export default function PatientsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { patientId } = useParams();
  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);

  if (!patientId || !state.isLoaded) {
    return null;
  }

  const sectionName = searchParams.get("section");
  const resourceId = searchParams.get("resourceId");

  function setSelectedResource(sectionName: string | null, resourceId: string | null): void {
    if (sectionName && resourceId) {
      setSearchParams({ section: sectionName, resourceId });
      navigate(`${location.pathname}?section=${sectionName}&resourceId=${resourceId}`, {
        replace: true,
      });
    } else {
      setSearchParams({});
      navigate(location.pathname, { replace: true });
    }
  }

  const selectedResourceParams = {
    sectionName,
    resourceId,
    setSelectedResource,
  };

  const apiKeyGenerated = isApiKeyGenerated(state.keyIds);

  if (!apiKeyGenerated) {
    return <NoApiKey />;
  }

  const baseUrl = useMemo(() => {
    return getOSSBaseURL(isUserInSandbox);
  }, [isUserInSandbox]);

  return (
    <SharedStateProvider authToken={state.authToken} baseUrl={baseUrl} ehrDetails={undefined}>
      <PatientOverview patientId={patientId} selectedResourceParams={selectedResourceParams} />
    </SharedStateProvider>
  );
}
