import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorModeValue, } from "@chakra-ui/react";
import { useState } from "react";
import { Button } from "../button";
export default function PatientMatchesTable({ patientMatches, }) {
    const headerBackgroundColor = useColorModeValue("gray.300", "#2d3748");
    const headerTextColor = useColorModeValue("black", "white");
    return (_jsx(TableContainer, Object.assign({ borderRadius: "lg", border: "1px", borderColor: "gray.600" }, { children: _jsxs(Table, Object.assign({ variant: "simple" }, { children: [_jsx(Thead, Object.assign({ bg: headerBackgroundColor }, { children: _jsxs(Tr, { children: [_jsx(Th, Object.assign({ color: headerTextColor }, { children: "Organization" })), _jsx(Th, Object.assign({ color: headerTextColor }, { children: "Patient Name" })), _jsx(Th, Object.assign({ color: headerTextColor }, { children: "DOB" })), _jsx(Th, Object.assign({ color: headerTextColor }, { children: "Gender" })), _jsx(Th, {})] }) })), _jsx(Tbody, { children: patientMatches === null || patientMatches === void 0 ? void 0 : patientMatches.map((link, i) => {
                        return _jsx(ExpandableTableRow, { link: link }, i);
                    }) })] })) })));
}
function ExpandableTableRow({ link }) {
    var _a, _b, _c;
    const [showMore, setShowMore] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Tr, { children: [_jsx(Td, { children: link.name }), _jsx(Td, { children: (_a = link.patient) === null || _a === void 0 ? void 0 : _a.names.map((name, i) => {
                            var _a;
                            const parsedName = JSON.parse(name);
                            return `${parsedName.firstName} ${parsedName.lastName}${i < (((_a = link.patient) === null || _a === void 0 ? void 0 : _a.names.length) || 0) - 1 ? ", " : ""}`;
                        }) }), _jsx(Td, { children: (_b = link.patient) === null || _b === void 0 ? void 0 : _b.dob }), _jsx(Td, { children: (_c = link.patient) === null || _c === void 0 ? void 0 : _c.gender }), _jsx(Td, { children: _jsx(Button, Object.assign({ onClick: () => setShowMore(!showMore) }, { children: showMore ? "Show Less" : "Show More" })) })] }), showMore ? (_jsx(Tr, { children: _jsx(Td, Object.assign({ colSpan: 5 }, { children: _jsx("pre", { children: JSON.stringify(link, null, 2) }) })) })) : null] }));
}
