import { Box, Heading, Switch, Text, VStack } from "@chakra-ui/react";
import { useAnalyticsContext, Actions, Features } from "@metriport/shared-internal";
import { updateBaseURL as updateBaseURLOSS } from "../../api/apiOSS";
import { capture } from "../../shared/capture";
import { isDevEnv, isStagingEnv } from "../../shared/util";
import { useAppContext } from "../contexts/app";
import useMetriportToast from "../medical/shared-logic/useMetriportToast";
import { useIsUserInSandbox } from "../shared/useIsUserInSandbox";

export default function SandboxToggle({
  minimized,
  onToggle,
}: {
  minimized?: boolean;
  onToggle?: () => void;
}) {
  const toast = useMetriportToast();
  const { state } = useAppContext();
  const Analytics = useAnalyticsContext();
  const { isUserInSandbox, setIsUserInSandbox } = useIsUserInSandbox(state);
  const isMinimized = !!minimized;

  function onSandboxToggle() {
    const newIsSandbox = !isUserInSandbox;
    setIsUserInSandbox(newIsSandbox);
    updateBaseURLOSS(newIsSandbox);
    Analytics.emit(Actions.toggle, Features.sandbox, {
      isOn: newIsSandbox,
    });
    capture.updateSandboxEnvironment(newIsSandbox);
    if ((isStagingEnv() || isDevEnv()) && newIsSandbox) {
      toast.warning({
        title: "Heads up! You've enabled the sandbox mode in staging. Key generation is disabled.",
        duration: 5000,
      });
    }
    onToggle && onToggle();
  }

  return (
    <Box>
      <VStack align="left">
        {!isMinimized && (
          <>
            <Heading>Sandbox</Heading>
            <Text>
              Toggle the sandbox mode on/off to access your Sandbox-specific API key & settings.
              This is a website-wide action, all features in the dashboard are impacted by this
              change.
            </Text>
          </>
        )}
        <Switch
          id="sandbox-mode"
          size="lg"
          onChange={onSandboxToggle}
          isChecked={isUserInSandbox}
        />
      </VStack>
    </Box>
  );
}
