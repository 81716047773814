import { AllergyContent } from "./allergies/sidepanel-content";
import { allergyTableData } from "./allergies/table-data";
import { ConditionContent } from "./conditions/sidepanel-content";
import { conditionTableData } from "./conditions/table-data";
import { CoverageContent } from "./coverages/sidepanel-content";
import { coverageTableData } from "./coverages/table-data";
import { EncounterContent } from "./encounters/sidepanel-content";
import { encounterTableData } from "./encounters/table-data";
import { FamilyMemberHistoryContent } from "./family-member-histories/sidepanel-content";
import { familyMemberHistoryTableData } from "./family-member-histories/table-data";
import { ImmunizationContent } from "./immunizations/sidepanel-content";
import { immunizationTableData } from "./immunizations/table-data";
import { LabContent } from "./labs/sidepanel-content";
import { labTableData } from "./labs/table-data";
import { MedicationContent } from "./medications/sidepanel-content";
import { medicationTableData } from "./medications/table-data";
import { ObservationContent } from "./observations/sidepanel-content";
import { observationTableData } from "./observations/table-data";
import { ProcedureContent } from "./procedures/sidepanel-content";
import { procedureTableData } from "./procedures/table-data";
import { RelatedPersonContent } from "./related-persons/sidepanel-content";
import { relatedPersonTableData } from "./related-persons/table-data";
import { ReportContent } from "./reports/sidepanel-content";
import { reportTableData } from "./reports/table-data";
import { SocialHistoryContent } from "./social-history/sidepanel-content";
import { socialHistoryTableData } from "./social-history/table-data";
import { VitalContent } from "./vitals/sidepanel-content";
import { vitalTableData } from "./vitals/table-data";
export const fhirSections = [
    {
        title: "Reports",
        key: "reports",
        generateTableData: reportTableData,
        sidePanelContent: ReportContent,
    },
    {
        title: "Conditions",
        key: "conditions",
        generateTableData: conditionTableData,
        sidePanelContent: ConditionContent,
    },
    {
        title: "Medications",
        key: "medications",
        generateTableData: medicationTableData,
        sidePanelContent: MedicationContent,
    },
    {
        title: "Allergies",
        key: "allergies",
        generateTableData: allergyTableData,
        sidePanelContent: AllergyContent,
    },
    {
        title: "Procedures",
        key: "procedures",
        generateTableData: procedureTableData,
        sidePanelContent: ProcedureContent,
    },
    {
        title: "Social History",
        key: "social-history",
        generateTableData: socialHistoryTableData,
        sidePanelContent: SocialHistoryContent,
    },
    {
        title: "Vitals",
        key: "vitals",
        generateTableData: vitalTableData,
        sidePanelContent: VitalContent,
    },
    {
        title: "Labs",
        key: "labs",
        generateTableData: labTableData,
        sidePanelContent: LabContent,
    },
    {
        title: "Observations",
        key: "observations",
        generateTableData: observationTableData,
        sidePanelContent: ObservationContent,
    },
    {
        title: "Immunizations",
        key: "immunizations",
        generateTableData: immunizationTableData,
        sidePanelContent: ImmunizationContent,
    },
    {
        title: "Family Member History",
        key: "family-member-history",
        generateTableData: familyMemberHistoryTableData,
        sidePanelContent: FamilyMemberHistoryContent,
    },
    {
        title: "Related Persons",
        key: "related-persons",
        generateTableData: relatedPersonTableData,
        sidePanelContent: RelatedPersonContent,
    },
    {
        title: "Coverage",
        key: "coverages",
        generateTableData: coverageTableData,
        sidePanelContent: CoverageContent,
    },
    {
        title: "Encounters",
        key: "encounters",
        generateTableData: encounterTableData,
        sidePanelContent: EncounterContent,
    },
];
