import { compare, getResourcesFromBundle } from "../shared";
export const coverageTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "provider" },
        { field: "policyId" },
        { field: "status" },
    ];
    const coverages = getResourcesFromBundle(bundle, "Coverage");
    const organizations = getResourcesFromBundle(bundle, "Organization");
    return {
        columnDefs,
        rowData: getCoverageRowData({ coverages, organizations, tableFilters }),
    };
};
function getCoverageRowData({ coverages, organizations, tableFilters, }) {
    return coverages === null || coverages === void 0 ? void 0 : coverages.map(coverage => {
        var _a, _b;
        return ({
            id: (_a = coverage.id) !== null && _a !== void 0 ? _a : "-",
            provider: getCoverageOrganization(coverage, organizations),
            policyId: getPolicyId(coverage),
            status: (_b = coverage.status) !== null && _b !== void 0 ? _b : "-",
        });
    }).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getCoverageOrganization(coverage, organizations) {
    var _a, _b, _c, _d, _e;
    const payorRef = (_d = (_c = (_b = (_a = coverage.payor) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.reference) === null || _c === void 0 ? void 0 : _c.split("/")) === null || _d === void 0 ? void 0 : _d[1];
    if (payorRef) {
        const organization = organizations.find(org => org.id === payorRef);
        if (organization) {
            return (_e = organization.name) !== null && _e !== void 0 ? _e : "-";
        }
    }
    return "-";
}
function getPolicyId(coverage) {
    const policyIds = [];
    if (coverage.identifier) {
        for (const identifier of coverage.identifier) {
            if (identifier.value) {
                policyIds.push(identifier.value);
            }
        }
    }
    return policyIds.join(", ");
}
