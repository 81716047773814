var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from "react";
import dayjs from "dayjs";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useAnalyticsContext, Features, Actions } from "./analytics-context";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();
export function useDownloadFile() {
    const toast = useMetriportToast();
    const metriportApi = useMetriportApi();
    const Analytics = useAnalyticsContext();
    const [isDownloading, setIsDownloading] = useState({});
    function onDownloadFile(docId, docFilename, conversionType) {
        return __awaiter(this, void 0, void 0, function* () {
            Analytics.emit(Actions.download, Features.document);
            setIsDownloading(Object.assign(Object.assign({}, isDownloading), { [docId]: {
                    downloading: true,
                    type: conversionType !== null && conversionType !== void 0 ? conversionType : "xml",
                } }));
            try {
                if (conversionType) {
                    toast.info({
                        title: "Hold tight... This might take up to 30 seconds.",
                        duration: EXPLAIN_TOAST_DURATION,
                    });
                }
                const resp = yield metriportApi.getDocumentUrl(docFilename, conversionType);
                const a = document.createElement("a");
                a.href = resp.url;
                a.download = docFilename;
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            catch (err) {
                capture.error(err, { extra: { docId: docId, context: `patient.file.download` } });
                toast.error();
            }
            setIsDownloading(Object.assign(Object.assign({}, isDownloading), { [docId]: {
                    downloading: false,
                } }));
        });
    }
    return { onDownloadFile, isDownloading };
}
