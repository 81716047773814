var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input as ChakraInput, Text } from "@chakra-ui/react";
import React from "react";
import { Label } from "../Label";
const enterKeys = ["NumpadEnter", "Enter"];
export const Input = React.forwardRef((_a, ref) => {
    var _b;
    var { label, error, disabled, onEnterKeyPress } = _a, rest = __rest(_a, ["label", "error", "disabled", "onEnterKeyPress"]);
    return (_jsxs(Box, Object.assign({ flex: 1, w: "100%" }, { children: [!!label && _jsx(Label, { children: label }), _jsx(ChakraInput, Object.assign({ disabled: disabled, ref: ref, isInvalid: !!error }, rest, { onKeyUp: key => {
                    if (onEnterKeyPress && enterKeys.includes(key.code))
                        onEnterKeyPress();
                } })), !!error && _jsx(Text, Object.assign({ mt: 2 }, { children: (_b = error.message) === null || _b === void 0 ? void 0 : _b.toString() }))] })));
});
