import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { getFirstCodeSpecified, ICD_10_CODE, SNOMED_CODE, compare, filterByDate, getValidCode, } from "../shared";
import { getResourcesFromBundle } from "../shared";
export const conditionTableData = ({ bundle, tableFilters }) => {
    const columnDefs = [
        { field: "id", hide: true },
        { field: "condition" },
        { field: "code" },
        { field: "onsetTime", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
        { field: "lastSeen", sort: (tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter) ? undefined : "desc" },
    ];
    const conditions = getResourcesFromBundle(bundle, "Condition");
    const groupedConditions = groupConditions(conditions);
    return {
        columnDefs,
        rowData: getConditionRowData({ conditions: groupedConditions, tableFilters }),
    };
};
export function groupConditions(conditions) {
    const results = [];
    const conditionMap = {};
    conditions.map(c => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        let title;
        const codings = getValidCode((_a = c.code) === null || _a === void 0 ? void 0 : _a.coding);
        const displays = codings.map(coding => coding.display);
        const text = (_b = c.code) === null || _b === void 0 ? void 0 : _b.text;
        if (displays.length) {
            title = Array.from(new Set(displays)).join(", ");
        }
        else if (text) {
            title = text;
        }
        else {
            results.push({ title: "-", mostRecentCondition: c });
            return;
        }
        if (!c.onsetPeriod && !c.onsetDateTime) {
            results.push({ title, mostRecentCondition: c });
            return;
        }
        const conditionPoint = {
            rawCondition: c,
            start: getOnsetTime(c),
            end: ((_c = c.onsetPeriod) === null || _c === void 0 ? void 0 : _c.end) ? dayjs(c.onsetPeriod.end).format(ISO_DATE) : undefined,
            status: ((_j = (_e = (_d = c.clinicalStatus) === null || _d === void 0 ? void 0 : _d.text) !== null && _e !== void 0 ? _e : (_h = (_g = (_f = c.clinicalStatus) === null || _f === void 0 ? void 0 : _f.coding) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.display) !== null && _j !== void 0 ? _j : ((_m = (_l = (_k = c.clinicalStatus) === null || _k === void 0 ? void 0 : _k.coding) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.code) === "55561003")
                ? "Active"
                : (_q = (_p = (_o = c.clinicalStatus) === null || _o === void 0 ? void 0 : _o.coding) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.code,
        };
        const groupedCondition = conditionMap[title];
        if (groupedCondition) {
            groupedCondition.push(conditionPoint);
        }
        else {
            conditionMap[title] = [conditionPoint];
        }
    });
    Object.entries(conditionMap).map(([title, values]) => {
        const sortedOccurrences = values.sort((a, b) => {
            const dateA = a.start ? new Date(a.start).getTime() : 0;
            const dateB = b.start ? new Date(b.start).getTime() : 0;
            return dateB - dateA;
        });
        const mostRecent = sortedOccurrences[0];
        if (!mostRecent)
            return;
        results.push({
            title,
            mostRecentCondition: mostRecent.rawCondition,
            sortedOccurrences,
            status: mostRecent.status,
        });
    });
    return results;
}
function getConditionRowData({ conditions, tableFilters, }) {
    return conditions === null || conditions === void 0 ? void 0 : conditions.map(condition => {
        var _a;
        return ({
            id: (_a = condition.mostRecentCondition.id) !== null && _a !== void 0 ? _a : "-",
            condition: condition.title,
            code: getConditionCode(condition.mostRecentCondition),
            onsetTime: getEarliestSeen(condition.sortedOccurrences),
            lastSeen: getLatestSeen(condition.sortedOccurrences),
        });
    }).filter(row => filterByDate(row.onsetTime, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.dateFilter)).sort((a, b) => compare(a, b, tableFilters === null || tableFilters === void 0 ? void 0 : tableFilters.stringFilter));
}
function getConditionCode(condition) {
    var _a, _b;
    const coding = getFirstCodeSpecified((_b = (_a = condition.code) === null || _a === void 0 ? void 0 : _a.coding) !== null && _b !== void 0 ? _b : [], [ICD_10_CODE, SNOMED_CODE]);
    return coding ? `${coding.system}: ${coding.code}` : "-";
}
function getOnsetTime(condition) {
    var _a, _b;
    const onsetDateTime = condition.onsetDateTime;
    const onsetPeriodStart = (_a = condition.onsetPeriod) === null || _a === void 0 ? void 0 : _a.start;
    const onsetPeriodEnd = (_b = condition.onsetPeriod) === null || _b === void 0 ? void 0 : _b.end;
    const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;
    if (time) {
        return dayjs(time).format(ISO_DATE);
    }
    return undefined;
}
function getEarliestSeen(occurrences) {
    var _a;
    const earliest = occurrences === null || occurrences === void 0 ? void 0 : occurrences.reduce((acc, curr) => {
        if (!acc || (curr.start && acc.start && new Date(curr.start) < new Date(acc.start))) {
            return curr;
        }
        return acc;
    }, null);
    return (_a = earliest === null || earliest === void 0 ? void 0 : earliest.start) !== null && _a !== void 0 ? _a : "-";
}
function getLatestSeen(occurrences) {
    var _a;
    const latest = occurrences === null || occurrences === void 0 ? void 0 : occurrences.reduce((acc, curr) => {
        if (!acc || (curr.end && acc.end && new Date(curr.end) > new Date(acc.end))) {
            return curr;
        }
        return acc;
    }, null);
    return (_a = latest === null || latest === void 0 ? void 0 : latest.end) !== null && _a !== void 0 ? _a : "-";
}
