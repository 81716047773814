var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
export const RX_NORM_CODE = "rxnorm";
export const NDC_CODE = "ndc";
export const SNOMED_CODE = "snomed";
export const ICD_10_CODE = "icd-10";
export const ICD_9CM_CODE = "icd-9cm";
export const LOINC_CODE = "loinc";
export const MEDICARE_CODE = "medicare";
export const CPT_CODE = "cpt";
export const EPIC_CODE = "114350";
export const UNK_CODE = "UNK";
export const UNKNOWN_DISPLAY = "unknown";
const knownSystems = [
    RX_NORM_CODE,
    NDC_CODE,
    SNOMED_CODE,
    ICD_10_CODE,
    ICD_9CM_CODE,
    LOINC_CODE,
    MEDICARE_CODE,
    CPT_CODE,
    EPIC_CODE,
    UNK_CODE,
    UNKNOWN_DISPLAY,
];
export function mapCodeToSystem(coding) {
    var _a, _b;
    const codeMap = {};
    for (const code of coding) {
        codeMap[(_a = code.system) !== null && _a !== void 0 ? _a : UNK_CODE] = (_b = code.code) !== null && _b !== void 0 ? _b : UNKNOWN_DISPLAY;
    }
    return codeMap;
}
export function getSystemDisplay(system) {
    const known = knownSystems.find(knownSystem => system.toLowerCase().includes(knownSystem.toLowerCase()));
    if (known) {
        if (known === EPIC_CODE)
            return "EPIC";
        return known.toUpperCase();
    }
    return system;
}
export function getFirstCodeSpecified(coding, systemsList) {
    var _a, _b;
    let specifiedCode = undefined;
    if (systemsList.length && coding) {
        for (const system of systemsList) {
            const obj = coding.find(coding => {
                var _a;
                return ((_a = coding.system) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(system)) && coding.code;
            });
            if (obj) {
                specifiedCode = {
                    system: system ? system.toUpperCase() : UNK_CODE,
                    code: (_a = obj.code) !== null && _a !== void 0 ? _a : UNKNOWN_DISPLAY,
                    display: (_b = obj.display) !== null && _b !== void 0 ? _b : UNKNOWN_DISPLAY,
                };
                break;
            }
        }
    }
    return specifiedCode;
}
export function getValidCode(coding) {
    if (!coding)
        return [];
    return coding.filter(coding => {
        return (coding.code &&
            coding.code !== UNK_CODE &&
            coding.display &&
            coding.display.toLowerCase() !== UNKNOWN_DISPLAY);
    });
}
export function getResourcesFromBundle(bundle, resourceType) {
    if (!bundle) {
        return [];
    }
    const resourceMap = bundle[resourceType];
    if (!resourceMap) {
        return [];
    }
    return Object.values(resourceMap);
}
export function downloadSourceDocument(docFilename, metriportApi) {
    return __awaiter(this, void 0, void 0, function* () {
        const resp = yield metriportApi.getDocumentUrl(docFilename, "html");
        const a = document.createElement("a");
        a.href = resp.url;
        a.download = docFilename;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
}
export function filterByDate(date, dateFilter) {
    if (dateFilter) {
        const isoDate = dayjs(date, ISO_DATE);
        if (dateFilter.from && isoDate.isBefore(dayjs(dateFilter.from))) {
            return false;
        }
        if (dateFilter.to && isoDate.isAfter(dayjs(dateFilter.to))) {
            return false;
        }
    }
    return true;
}
export function compare(a, b, stringFilter) {
    const keys = Object.keys(a);
    for (const key of keys) {
        if (stringFilter) {
            const filtersArray = stringFilter.split(",");
            const aHasFilter = filtersArray.some(filter => {
                var _a;
                return (_a = a[key]) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase());
            });
            const bHasFilter = filtersArray.some(filter => {
                var _a;
                return (_a = b[key]) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase());
            });
            if (aHasFilter && !bHasFilter) {
                return -1;
            }
            else if (!aHasFilter && bHasFilter) {
                return 1;
            }
        }
    }
    return 0;
}
export function formatDate(date) {
    return dayjs(date).format(ISO_DATE);
}
