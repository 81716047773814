import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer, useContext } from "react";
import { initialState, reducer, } from "./reducer";
export const PatientConsolidatedStateContext = createContext({
    patientConsolidatedState: initialState,
    patientConsolidatedDispatch: () => null,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PatientConsolidatedStateProvider = ({ children }) => {
    const [patientConsolidatedState, patientConsolidatedDispatch] = useReducer(reducer, initialState);
    return (_jsx(PatientConsolidatedStateContext.Provider, Object.assign({ value: { patientConsolidatedState, patientConsolidatedDispatch } }, { children: children })));
};
export const usePatientConsolidatedContext = () => useContext(PatientConsolidatedStateContext);
